import { HttpTransportType, HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { inject, injectable } from 'inversify';
import { makeAutoObservable } from 'mobx';
import { API_URL } from '../constantsHub/constants';
import { Types } from '../inversify/inversify.types';
import { ITaskViewDTO } from '../dto/ITaskViewDTO';
import { SignalRSubscribers } from './signalRSubscribers';
import { ITaskQueueDTO } from '../dto/ITaskQueueDTO';

@injectable()
export class SignalR {
	@inject(Types.SignalRSubscribers) private _signalRSubscribers!: SignalRSubscribers;

	private _connection: HubConnection | undefined;

	constructor() {
		makeAutoObservable(this);
	}

	public async initHub(taskId: string): Promise<void> {
		this._connection = new HubConnectionBuilder()
			.withUrl(`${API_URL}/taskHub/`, {
				skipNegotiation: true,
				transport: HttpTransportType.WebSockets,
			})
			.withAutomaticReconnect()
			.build();

		await this._connection.start();
		await this.addTaskStateFunctionListener();
		await this.addTaskQueueFunctionListener();
		await this.registerTask(taskId);
	}

	private async registerTask(id: string): Promise<void> {
		await this._connection?.invoke('GetTaskState', id);
	}

	private async addTaskStateFunctionListener(): Promise<void> {
		if (this._connection) {
			this._connection.on('TaskStateReceive', (taskState: ITaskViewDTO) => {
				this._signalRSubscribers?.taskStateSubscriber.TriggerEvent(taskState);
			});
		}
	}

	private async addTaskQueueFunctionListener(): Promise<void> {
		if (this._connection) {
			this._connection.on('TaskQueueReceive', (taskState: ITaskQueueDTO) => {
				this._signalRSubscribers?.taskQueueSubscriber.TriggerEvent(taskState);
			});
		}
	}

	public stopHub(): void {
		this._connection?.stop();
	}
}
