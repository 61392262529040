export class ErrorFieldNameConstants {
	static get emailError(): string {
		return 'DTO.Email';
	}

	static get fileError(): string {
		return 'File';
	}

	static get processingError(): string {
		return 'ProcessingError';
	}

	static get connectionError(): string {
		return 'Connection';
	}
}
