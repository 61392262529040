import '../sizeButton.scss';
import './plusButton.scss';

import { HTMLAttributes } from 'react';

export const PlusButton = ({ ...props }: HTMLAttributes<HTMLDivElement>): JSX.Element => {
	return (
		<div className='size-button__plus size-button' {...props}>
			<span className='size-button__plus-vertical'></span>
			<span className='size-button__plus-horizontal'></span>
		</div>
	);
};
