import { injectable } from 'inversify';
import { ITaskQueueDTO } from '../dto/ITaskQueueDTO';
import { ITaskViewDTO } from '../dto/ITaskViewDTO';
import { EventSubscriber } from './eventSubscriber';

@injectable()
export class SignalRSubscribers {
	taskStateSubscriber = new EventSubscriber<ITaskViewDTO>();
	taskQueueSubscriber = new EventSubscriber<ITaskQueueDTO>();
}
