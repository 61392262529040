import './notificationBar.scss';

import classNames from 'classnames';
import { observer } from 'mobx-react';
import { useEffect } from 'react';

interface INotificationBarProps {
	text: string;
	className?: string;
	closeNotify: () => void;
}

export const NotificationBar = observer(
	({ text, className, closeNotify, ...props }: INotificationBarProps): JSX.Element => {
		useEffect(() => {
			setTimeout((): void => {
				closeNotify();
			}, 5000);
		}, []);

		return <div className={classNames('notification', className)}>{text}</div>;
	},
);
