import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { Navigator } from '../../components/navigator/Navigator';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import { history } from '../../routing/history';
import './agreement.scss';
import { NoticeAndTakeDownPolicy } from './text/kz/NoticeAndTakeDownPolicy';
import { PrivacyPolicy } from './text/kz/PrivacyPolicy';
import { TermsOfService } from './text/kz/TermsOfService';

import useScrollPosition from '../../hooks/useScrollPosition';
import { ReactComponent as ArrowUpIcon } from '../../img/general-icons/arrowUpCircleFill.svg';

export const Agreement = observer((): JSX.Element => {
	const { page } = useParams();
	const { width, height } = useWindowDimensions();
	const scrollTop = useScrollPosition();

	const SCREEN_SIZE_TO_SWAP = 700;

	const arrowUpHandle = (): void => {
		window.scrollTo(0, 0);
	};

	const desktopVariant = (): JSX.Element => {
		return (
			<>
				<div className='agreement__left'>{renderLeftText()}</div>
				<div className='agreement__right'>
					<Navigator isNumList={page === 'terms'} />
				</div>
			</>
		);
	};

	const mobileVariant = (): JSX.Element => {
		return <div className='agreement__left'>{renderLeftText(<Navigator isNumList={page === 'terms'} />)}</div>;
	};

	const renderLeftText = (children?: JSX.Element): JSX.Element => {
		switch (page) {
			case 'terms':
				return <TermsOfService>{children}</TermsOfService>;
			case 'privacy':
				return <PrivacyPolicy>{children}</PrivacyPolicy>;
			case 'notice':
				return <NoticeAndTakeDownPolicy>{children}</NoticeAndTakeDownPolicy>;
			default:
				history.push('/error');
				return <></>;
		}
	};

	const renderContainer = (): JSX.Element => {
		if (width > SCREEN_SIZE_TO_SWAP) {
			return desktopVariant();
		} else {
			return mobileVariant();
		}
	};

	const renderArrayUp = (): JSX.Element => {
		return scrollTop > height ? <ArrowUpIcon onClick={arrowUpHandle} /> : <></>;
	};

	return (
		<div className='agreement__body'>
			<div className='agreement__content'>
				{renderContainer()}
				{renderArrayUp()}
			</div>
		</div>
	);
});
