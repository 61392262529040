import './body.scss';

import { observer } from 'mobx-react-lite';
import { Outlet, Route, Routes } from 'react-router-dom';
import { ErrorPage } from '../../pages/error/ErrorPage';
import { ProcessPage } from '../../pages/process/ProcessPage';
import { UploadPage } from '../../pages/upload/UploadPage';
import { Title } from '../UI/pageTitle/PageTitle';
import { Agreement } from '../../pages/agreement/Agreement';

export const Body = observer((): JSX.Element => {
	return (
		<div className='app__body body-app'>
			<div className='body-app__container'>
				<Routes>
					<Route
						path='/'
						element={
							<>
								<Title />
								<Outlet />
							</>
						}
					>
						<Route path='/' element={<UploadPage />} />
						<Route path='/process/:id' element={<ProcessPage />} />
					</Route>
					<Route path='/agreement/:page' element={<Agreement />} />
					<Route path='/*' element={<ErrorPage />} />
				</Routes>
			</div>
		</div>
	);
});
