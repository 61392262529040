import './uploadPage.scss';

import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useInject } from '../../hooks/useInject';
import { Types } from '../../inversify/inversify.types';
import { GeneralStore } from '../../stores/generalStore';

import { Input } from '../../components/UI/input/Input';
import { UploadManager } from '../../components/upload/uploadManager/UploadManager';
import { ErrorFieldNameConstants } from '../../constantsHub/errorFiledNameConstants';
import windGenerators from '../../img/wind-generators.png';
import { ErrorStore } from '../../stores/errorStore';
import UploadStore from '../../stores/uploadStore';
import { Link } from 'react-router-dom';

export const UploadPage = observer((): JSX.Element => {
	const generalStore = useInject<GeneralStore>(Types.GeneralStore);
	const uploadStore = useInject<UploadStore>(Types.UploadStore);
	const errorStore = useInject<ErrorStore>(Types.ErrorStore);

	const resetEmailInput = (): void => {
		uploadStore.setEmail('');
	};

	useEffect(() => {
		generalStore.setPageTitle('Upload archive');
		resetEmailInput();
	}, []);

	return (
		<div className='upload-page__body'>
			<div className='upload-page__content'>
				<p>You can specify your email to send the results after processing is completed</p>
				<Input
					type={'email'}
					placeholder={'Email'}
					onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
						uploadStore.setEmail(e.currentTarget.value);
						errorStore.removeError(ErrorFieldNameConstants.emailError);
					}}
					value={uploadStore.email}
					inputName={ErrorFieldNameConstants.emailError}
					errorStore={errorStore}
				/>
				<p className='upload-page__email-confirm'>
					Entering email confirms your consent to the{' '}
					<Link to='/agreement/terms'>processing of personal data</Link> and{' '}
					<Link to='/agreement/privacy'>privacy policy</Link>
				</p>
				<UploadManager />
			</div>
			<div className='upload-page__requirements upload-requirements'>
				<div className='upload-requirements__form'>
					<h1>Archive Requirements</h1>

					<div className='upload-requirements__text'>
						The downloaded archive must be in .zip format and contain photos of one side of one wind turbine
						blade and a .txt file containing the names of the photos. Each photo name must start on a new
						line. The stitching order of the photos will be determined by the order of the photos in the
						.txt file. If there is no .txt file in the archive, then the ordering will be by the name of the
						photos. Photos must be in JPEG, JPG or PNG format. The photo size on one of the sides must be at
						least 400px and not more than 10000px.
						<ul>
							The photographs must show the blades:
							<li>blades should occupy more than 20% of the area in the images</li>
							<li>on the last image, the blade should occupy at least 50%</li>
						</ul>
						If the photos in the archive were not ordered or contain empty files, the system will not be
						able to create a single image of the blade, the image will consist of several logically complete
						sections.
					</div>
				</div>
				<img src={windGenerators} alt='windGenerator' />
			</div>
		</div>
	);
});
