import { observer } from 'mobx-react-lite';
import { InputHTMLAttributes } from 'react';

export const RadioButton = observer(({ children, ...props }: InputHTMLAttributes<HTMLInputElement>): JSX.Element => {
	return (
		<label>
			<input type='radio' {...props} />
			{children}
		</label>
	);
});
