import './samplesZone.scss';

import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useInject } from '../../../hooks/useInject';
import { Types } from '../../../inversify/inversify.types';
import { ProgressStore } from '../../../stores/progressStore';
import UploadStore from '../../../stores/uploadStore';
import { RadioButton } from '../../UI/radioButton/RadioButton';

export const SamplesZone = observer((): JSX.Element => {
	const uploadStore = useInject<UploadStore>(Types.UploadStore);
	const progressStore = useInject<ProgressStore>(Types.ProgressStore);

	const onChangeValue = (event: React.ChangeEvent<HTMLInputElement>): void => {
		uploadStore.setFileId(event.target.value);
	};

	useEffect(() => {
		progressStore.getSamples();
	}, []);

	return (
		<div className='samples-zone' onChange={onChangeValue}>
			{progressStore.samples &&
				progressStore.samples.map(
					(sample): JSX.Element => (
						<RadioButton key={sample.Id} value={sample.Id} name={'samples'}>
							{sample.Name}
						</RadioButton>
					),
				)}
		</div>
	);
});
