import './openSeaDragonViewer.scss';

import { observer } from 'mobx-react-lite';
import OpenSeaDragon, { ZoomEvent } from 'openseadragon';
import { useEffect, useState } from 'react';
import { API_URL } from '../../constantsHub/constants';
import { MathHelper } from '../../helpers/mathHelper';
import { useInject } from '../../hooks/useInject';
import { Types } from '../../inversify/inversify.types';
import { ProcessStore } from '../../stores/processStore';

interface IOpenSeaDragonViewerProps {
	zoomLevelPercent?: number;
	handleZoomChange: (value: number | undefined) => void;
}

const OpenSeaDragonViewer = observer(
	({ zoomLevelPercent, handleZoomChange, ...props }: IOpenSeaDragonViewerProps): JSX.Element => {
		const processStore = useInject<ProcessStore>(Types.ProcessStore);
		const [viewer, setViewer] = useState<OpenSeaDragon.Viewer | null>(null);

		const screenRelation = window.screen.height / window.screen.width;
		const imageRelation =
			processStore.fileProperties?.Height && processStore.fileProperties?.Width
				? processStore.fileProperties?.Height / processStore.fileProperties?.Width
				: 1;

		const InitOpenseadragon = (): void => {
			viewer && viewer.destroy();
			const minZoomLevel = imageRelation < 1 ? imageRelation * screenRelation : screenRelation / imageRelation;
			setViewer(
				OpenSeaDragon({
					id: 'openSeaDragon',
					prefixUrl: '//openseadragon.github.io/openseadragon/images/',
					tileSources: {
						type: 'image',
						height: processStore.fileProperties?.Height,
						width: processStore.fileProperties?.Width,
						tileSize: 256,
						minLevel: 1,
						maxLevel: 5,
						getTileUrl: (l: number, x: number, y: number) => {
							return `${API_URL}/task/${processStore.taskId}/tiles?x=${x}&y=${y}&level=${6 - l}`;
						},
					},
					zoomInButton: 'zoom-in',
					zoomOutButton: 'zoom-out',
					animationTime: 0.5,
					blendTime: 0.1,
					defaultZoomLevel: minZoomLevel,
					maxZoomLevel: 20,
					minZoomLevel: minZoomLevel,
					showNavigator: true,
				}),
			);
		};

		const createOverlays = (): void => {
			if (viewer) {
				processStore.defects.forEach((defect) => {
					viewer.removeOverlay(`defect-${defect.Name}`);
				});
				processStore.defects
					.filter((defect) => defect.Threshold >= processStore.threshold)
					.forEach((defect) => {
						const res = {
							id: `defect-${defect.Name}`,
							x: (defect.Right + defect.Left) / 2,
							y: (defect.Top + defect.Bottom) / 2,
							height: defect.Top - defect.Bottom,
							width: defect.Right - defect.Left,
							className: 'openSeaDragon-defect',
						};

						if (imageRelation > 1) {
							res.y *= imageRelation;
							res.height *= imageRelation;
						} else {
							res.x *= imageRelation;
							res.width *= imageRelation;
						}

						const elt = document.createElement('div');
						elt.id = res.id;
						elt.className = res.className;

						viewer.addOverlay(elt, new OpenSeaDragon.Rect(res.x, res.y, res.width, res.height));
					});
			}
		};

		useEffect(() => {
			InitOpenseadragon();
			processStore.getTaskDefects();
			return (): void => {
				viewer && viewer.destroy();
			};
		}, []);

		useEffect(() => {
			createOverlays();
		}, [processStore.threshold, viewer, processStore.defects]);

		useEffect(() => {
			if (viewer && processStore.fileProperties?.Height && processStore.fileProperties?.Width) {
				const minZoomLevel =
					imageRelation < 1 ? imageRelation * screenRelation : screenRelation / imageRelation;
				const maxZoomLevel = viewer.viewport.getMaxZoom();
				const currentZoomLevel = viewer.viewport.getZoom();
				const currentZoomPercent = MathHelper.getValuePercentInRange(
					currentZoomLevel,
					minZoomLevel,
					maxZoomLevel,
				);

				handleZoomChange(100 - currentZoomPercent);

				viewer.addHandler('zoom', (event: ZoomEvent): void => {
					handleZoomChange(100 - MathHelper.getValuePercentInRange(event.zoom, minZoomLevel, maxZoomLevel));
				});
			}
		}, [viewer]);

		useEffect(() => {
			if (
				viewer &&
				processStore.fileProperties?.Height &&
				processStore.fileProperties?.Width &&
				zoomLevelPercent
			) {
				const minZoomLevel =
					imageRelation < 1 ? imageRelation * screenRelation : screenRelation / imageRelation;
				const maxZoomLevel = viewer.viewport.getMaxZoom();

				viewer.viewport.zoomTo(
					MathHelper.getValueInRangOfPercent(zoomLevelPercent, minZoomLevel, maxZoomLevel),
				);
			}
		}, [zoomLevelPercent]);

		return <div id='openSeaDragon' className='openSeaDragon'></div>;
	},
);

export { OpenSeaDragonViewer };
