import './fileProcessing.scss';

import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useParams } from 'react-router-dom';
import { useInject } from '../../../hooks/useInject';
import { ReactComponent as PlusIcon } from '../../../img/general-icons/plus.svg';
import { Types } from '../../../inversify/inversify.types';
import { history } from '../../../routing/history';
import { NavigateLinks } from '../../../routing/navigateLinks';
import { ProcessStore } from '../../../stores/processStore';
import { ETaskState } from '../../../dto/ETaskState';
import { Button } from '../../UI/button/Button';
import { ProcessBar } from '../processBar/ProcessBar';
import { ErrorStore } from '../../../stores/errorStore';
import classNames from 'classnames';
import { ErrorFieldNameConstants } from '../../../constantsHub/errorFiledNameConstants';
import { NotificationBar } from '../../UI/notification/NotificationBar';
import { TaskResultState } from '../../../dto/TaskResultState';

import { ReactComponent as ErrorIcon } from '../../../img/general-icons/error.svg';
import { ReactComponent as WarningIcon } from '../../../img/general-icons/warning.svg';

export const FileProcessing = observer((): JSX.Element => {
	const [handleNotify, setHandleNotify] = useState(false);

	const processStore = useInject<ProcessStore>(Types.ProcessStore);
	const errorStore = useInject<ErrorStore>(Types.ErrorStore);

	const { id } = useParams();

	const getTxtToCopyPageLinkInBuffer = (): string => {
		const currentUrl = window.location.href;
		return currentUrl;
	};

	const goToMainPage = (): void => {
		history.push(NavigateLinks.mainPage);
	};

	useEffect(() => {
		processStore.setTaskId(id);
		processStore.getTaskState();
		return (): void => {
			processStore.stopSignalR();
		};
	}, []);

	return (
		<div className='file-processing'>
			<div className='file-processing__title'>
				{processStore.taskState === ETaskState.Waiting ? (
					<div className='file-processing__queue'>
						Your archive is <span>{processStore.queueNumber}</span> in the processing queue. Please wait a
						bit...
					</div>
				) : (
					errorStore.getErrorListByName(ErrorFieldNameConstants.processingError)?.map((item, indx) => {
						return (
							<div
								className={classNames(
									'file-processing__error',
									processStore.taskState !== undefined ? ETaskState[processStore.taskState] : '',
								)}
								key={`${item.PropertyName}-error-${indx}`}
							>
								{processStore.taskResultState === TaskResultState.CompletedWithWarning ? (
									<WarningIcon style={{ fill: '#ECBC42' }} />
								) : (
									processStore.taskResultState === TaskResultState.Error && (
										<ErrorIcon style={{ fill: '#D13A3A' }} />
									)
								)}
								<div className='file-processing__error-text'>{item.Message}</div>
							</div>
						);
					})
				)}
			</div>
			<ProcessBar />
			<div className='file-processing__description'>
				{processStore.taskState === ETaskState.Completed &&
				(processStore.taskResultState === TaskResultState.Completed ||
					processStore.taskResultState === TaskResultState.CompletedWithWarning) ? (
					<>
						The processing result will only be available for 24 hours.
						<br />
						Don&apos;t forget to download your file within 24 hours to avoid losing it.
					</>
				) : processStore.taskResultState === TaskResultState.Error ? (
					<>The processing result will only be available for 24 hours.</>
				) : (
					<>
						Processing may take from 5 to 60 minutes.
						<br /> You can stay here waiting for the result, or{' '}
						<CopyToClipboard
							text={getTxtToCopyPageLinkInBuffer()}
							onCopy={(): void => {
								setHandleNotify(true);
							}}
						>
							<span>copy the link</span>
						</CopyToClipboard>{' '}
						to the page and visit it later.
					</>
				)}

				{handleNotify && (
					<NotificationBar
						text='Copied to clipboard'
						className='secondary'
						closeNotify={(): void => {
							setHandleNotify(false);
						}}
					/>
				)}
			</div>
			<div className='file-processing__btn'>
				<Button className='text-variant' onClick={goToMainPage}>
					One more <PlusIcon />
				</Button>
			</div>
		</div>
	);
});
