import './dropZone.scss';

import { observer } from 'mobx-react';
import Dropzone, { FileRejection } from 'react-dropzone';
import { ReactComponent as UploadCloud } from '../../../img/upload-cloud.svg';
import { useInject } from '../../../hooks/useInject';
import UploadStore from '../../../stores/uploadStore';
import { Types } from '../../../inversify/inversify.types';
import { ErrorStore } from '../../../stores/errorStore';
import { ErrorFieldNameConstants } from '../../../constantsHub/errorFiledNameConstants';

export const DropZone = observer((): JSX.Element => {
	const uploadStore = useInject<UploadStore>(Types.UploadStore);
	const errorStore = useInject<ErrorStore>(Types.ErrorStore);

	const handleDrop = (acceptedFiles: File[], fileRejections: FileRejection[]): void => {
		const allFileRejections = fileRejections.map((item) => item.file);
		const files = [...acceptedFiles, ...allFileRejections];
		if (files.length !== 0) {
			errorStore.removeError(ErrorFieldNameConstants.fileError);
			uploadStore.uploadFile(files[0]);
		}
	};

	return (
		<Dropzone onDrop={handleDrop} accept={{ 'text/rar': ['.zip'] }}>
			{({ getRootProps, getInputProps }): JSX.Element => (
				<div {...getRootProps({ className: 'dropzone' })}>
					<input {...getInputProps()} accept='.zip' />
					<div className='dropzone__top'>
						<UploadCloud />
						<p className='dropzone__title'>Drag & Drop</p>
						<p>or select files from device</p>
					</div>
					<div className='dropzone__bottom'>
						<p>The archive must contain from 2 to 30 images.</p>
						<p>max. 1 GB</p>
					</div>
				</div>
			)}
		</Dropzone>
	);
});
