import { HtmlHTMLAttributes } from 'react';

export const NoticeAndTakeDownPolicy = ({ children, ...props }: HtmlHTMLAttributes<HTMLDivElement>): JSX.Element => {
	return (
		<>
			<h1>Notice and Take Down Policy</h1>
			{children}
			<div>
				Singularis Programming respects your rights and expects that you respect the rights of others. In the
				unfortunate event that Singularis Programming finds or is notified of unlawful files or content being
				distributed through it’s Service, we will follow the procedures described in this Notice and Take Down
				Policy.{' '}
			</div>

			<div>By using our website, you agree to be bound by our Notice and Take Down Policy below.</div>
			<h3>Report requirements</h3>
			<div>
				Any person or organisation that makes a Report (“Notifier”) has the duty to ensure that it is correct
				and complete.{' '}
			</div>

			<div>
				Singularis Programming should be able to verify that Reports following an investigation regarding a
				criminal offence have originated from an inspection or investigation service, or – in the case of a
				formal legal order – from the Public Prosecutor’s Office. A Report of any other nature than the
				foregoing should include at least the following information:
			</div>
			<div>
				<ol>
					<li>Notifier’s contact details, name, function, organisation, address, email address);</li>
					<li>
						all information Singularis Programming needs in order to evaluate the lawfulness of the disputed
						content, including its location (URL);
					</li>
					<li>
						motivation as to the unlawfulness of the content, or why it is in conflict with AI Defect
						Detection’s Terms of Service, NTD or Privacy Policy;
					</li>
					<li>
						motivation of the reason(s) why Singularis Programming is the most appropriate intermediary to
						deal with the matter.
					</li>
				</ol>
			</div>
			<h3>Indemnity</h3>
			<div>
				Notifier will provide Singularis Programming with an explicit indemnity against 1) claims from the user
				who’s content is at dispute (“Content Provider”) relating to any actions Singularis Programming has
				taken in relation to handling the Report, and 2) any claims from Notifier in relation to content send
				through our Service.
			</div>
			<h3>Urgency</h3>
			<div>
				Notifier might request Singularis Programming to deal with the report as a matter of urgency. In that
				case, the Report should substantiate all reasons for urgency. Singularis Programming determines at its
				own discretion whether the report should be dealt with as a matter of urgency on the basis of the
				foregoing explanation.
			</div>
			<h3>Evaluation</h3>

			<div>Singularis Programming shall evaluate the Report and classify it into one of three categories:</div>
			<div>
				<ol>
					<li>
						punishable content reported by an inspection or investigation service, or – in the case of a
						formal legal order – by the Public Prosecutor’s Office;
					</li>
					<li>
						unequivocally unlawful content and/or punishable content reported by other sources than the
						authorities listed under 1); and
					</li>
					<li>all other content, falling neither under category 1) or 2).</li>
				</ol>
			</div>
			<h3>Measures</h3>
			<div>
				Singularis Programming’s categorisation of the Report determines which measures are taken to address the
				Report.
			</div>
			<div>
				<ul>
					<li>
						In the event that Singularis Programming determines that the content concerned is unequivocally
						unlawful or punishable, in other words falls under the above mentioned categories 1) and 2),
						Singularis Programming ensures that the content concerned is immediately removed;
					</li>
					<li>
						In the event that Singularis Programming determines that the content concerned is not
						unequivocally unlawful or punishable, in other words falls under the above mentioned category
						3), the content concerned shall not be removed. Singularis Programming will inform Notifier
						accordingly;
					</li>
					<li>
						In the event that Singularis Programming is not or insufficiently able to judge whether the
						content concerned is unequivocally unlawful or punishable, Singularis Programming will inform
						Content Provider about the Report with the request to (i) allow Singularis Programming to remove
						the content or (ii) to contact Notifier. If Notifier and Content Provider are unable to reach an
						agreement, Notifier can make an official report to the police if a criminal offence might be
						concerned. If it concerns content that is alleged to be unlawful under civil law, Notifier can
						bring the dispute with Content Provider before the Republic of Kazakhstan court of law. Should
						Content Provider be unwilling to sufficiently identify himself to Notifier, Singularis
						Programming has the right to (i) provide Notifier with the Content Provider’s name, email
						address and IP-address or (ii) to remove the content concerned.
					</li>
				</ul>
			</div>
			<h3>Period</h3>
			<div>
				Categorisation and appropriate measures will preferably take place as soon as possible, yet ultimately
				60 days after receiving the Report.
			</div>
			<h3>Due caution</h3>
			<div>
				In the events that content is to be removed, Singularis Programming shall exercise due caution to ensure
				that no more than the necessary content, in regards of the Report, is removed.
			</div>
			<h3>Questions?</h3>
			<div>
				For questions about this NTD Policy, please contact Singularis Programming by sending an email to{' '}
				<a href='mailto:aidd@singularis-lab.com'>aidd@singularis-lab.com</a>. We speak English.
			</div>
		</>
	);
};
