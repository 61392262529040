import { injectable } from 'inversify';
import { HttpRequest, HttpResponse } from 'tus-js-client';
import { API_URL } from '../../constantsHub/constants';
import { TusApi } from './base/tusApi';

@injectable()
export class UploadService extends TusApi {
	public async uploadFile(
		file: File,
		onProgress: (bytesUploaded: number, bytesTotal: number) => void,
		saveIdOnSuccess?: (id: string | undefined) => void,
		onAfterResponse?: (req: HttpRequest, res: HttpResponse) => void,
	): Promise<void> {
		const upload = this._post({
			file: file,
			options: {
				endpoint: `${API_URL}${this._apiUrlFabric.files}`,
				retryDelays: [0, 3000, 5000, 10000, 20000],
				metadata: {
					filename: file.name,
					filetype: file.type,
				},
				chunkSize: 1024 * 1024 * 10,
				onProgress,
				onAfterResponse,
			},
			saveIdOnSuccess,
		});

		return this._doApiRequest(upload);
	}

	public async stopUploadFile(): Promise<void> {
		this._upload?.abort(true);
	}
}
