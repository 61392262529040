export const Types = {
	GeneralStore: Symbol('GeneralStore'),
	UploadStore: Symbol('UploadStore'),
	ProgressStore: Symbol('ProgressStore'),
	UploadService: Symbol('UploadService'),
	ProgressService: Symbol('ProgressService'),
	ApiUrlFabric: Symbol('ApiUrlFabric'),
	SignalR: Symbol('SignalR'),
	SignalRSubscribers: Symbol('SignalRSubscribers'),
	ProcessStore: Symbol('ProcessStore'),
	ProcessService: Symbol('ProcessService'),
	ErrorStore: Symbol('ErrorStore'),
	ModalStore: Symbol('ModalStore'),
};
