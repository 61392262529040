export class NavigatorHelper {
	public static convertCollectionToArray(pageItems: NodeListOf<HTMLElement>): HTMLElement[] {
		const itemList = Array.from(pageItems);
		return itemList;
	}

	public static get urlToTemsOfServicePage(): string {
		return '/agreement/terms';
	}

	public static get urlToPrivacyPolicy(): string {
		return '/agreement/privacy';
	}

	public static get urlToNoticeAndTakeDownPolicy(): string {
		return '/agreement/notice';
	}
}
