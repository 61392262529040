import './uploadZone.scss';

import { ReactComponent as FileType } from '../../../img/file-type.svg';
import { useInject } from '../../../hooks/useInject';
import UploadStore from '../../../stores/uploadStore';
import { Types } from '../../../inversify/inversify.types';
import { observer } from 'mobx-react';

import { ReactComponent as CloseSvg } from '../../../img/general-icons/close.svg';
import { ProgressBar } from '../../UI/progressBar/ProgressBar';

export const UploadZone = observer((): JSX.Element => {
	const uploadStore = useInject<UploadStore>(Types.UploadStore);

	const stopUploadFile = (): void => {
		uploadStore.removeFile();
		if (!uploadStore.fileId) {
			uploadStore.stopUploadFile();
		}
	};

	return (
		<div className='upload-zone'>
			<CloseSvg className='upload-zone__remove' onClick={stopUploadFile} />
			<FileType />
			<p>{uploadStore.fileName}</p>
			<ProgressBar completed={uploadStore.fileLoadProgress} maxCompleted={uploadStore.fileLoadTotal} />
		</div>
	);
});
