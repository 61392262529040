import './styles/App.scss';

import { observer } from 'mobx-react';
import { Body } from './components/body/Body';
import { ErrorHeader } from './components/errorHeader/ErrorHeader';
import { Footer } from './components/footer/Footer';
import { Header } from './components/header/Header';
import { useInject } from './hooks/useInject';
import { Types } from './inversify/inversify.types';
import { ErrorStore } from './stores/errorStore';
import { ErrorFieldNameConstants } from './constantsHub/errorFiledNameConstants';

function App(): JSX.Element {
	const errorStore = useInject<ErrorStore>(Types.ErrorStore);

	return (
		<div className='app'>
			<Header />
			{errorStore.getErrorListByName(ErrorFieldNameConstants.connectionError)?.length ? <ErrorHeader /> : ''}
			<Body />
			<Footer />
		</div>
	);
}

export default observer(App);
