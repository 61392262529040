import { ETaskState } from '../dto/ETaskState';
import { TaskResultState } from '../dto/TaskResultState';

import { ReactComponent as ErrorIcon } from '../img/general-icons/error.svg';
import { ReactComponent as WarningIcon } from '../img/general-icons/warning.svg';
import { ReactComponent as CompletedIcon } from '../img/general-icons/completed.svg';
import { ReactComponent as ProcessingIcon } from '../img/general-icons/processing.svg';

export interface stateStatusListItem {
	icon: JSX.Element;
}

export class TaskStateConstants {
	public static readonly stateStatusList: Map<TaskResultState, stateStatusListItem> = new Map([
		[
			TaskResultState.Error,
			{
				icon: (
					<>
						<ErrorIcon />
					</>
				),
			},
		],
		[
			TaskResultState.Completed,
			{
				icon: (
					<>
						<CompletedIcon />
					</>
				),
			},
		],
		[
			TaskResultState.CompletedWithWarning,
			{
				icon: (
					<>
						<WarningIcon />
					</>
				),
			},
		],
		[
			TaskResultState.NotFinished,
			{
				icon: (
					<>
						<ProcessingIcon />
					</>
				),
			},
		],
	]);

	public static readonly taskStateProcessLineText: Map<ETaskState, string> = new Map([
		[ETaskState.Waiting, 'Waiting in the queue'],
		[ETaskState.UnzipData, 'Unpacking the archive '],
		[ETaskState.BuildingMasks, 'Building blade masks '],
		[ETaskState.DefectDetection, 'Defect detection'],
		[ETaskState.CombiningImages, 'Image stitching'],
		[ETaskState.Completed, 'Processing completed'],
	]);
}
