import { HtmlHTMLAttributes } from 'react';
import { NavigatorHelper } from '../../../../helpers/navigatorHelper';

export const TermsOfService = ({ children, ...props }: HtmlHTMLAttributes<HTMLDivElement>): JSX.Element => {
	return (
		<>
			<h2>Simple speak introduction for Terms of Service</h2>
			<div>
				So we’ve summed up our Terms of Service in a few simple sentences. They don’t replace the official legal
				stuff, but they will give you an idea of what’s in there. Any questions or concerns – please contact us.
				<br />
				<br />
				Here are the headlines (imagine them with a clock bonging in the background if you like):
				<ul>
					<li>You have to be 21 to use our tools.</li>
					<li>
						Your content is yours – you own it, and you are responsible for it. We’ll only do what’s needed
						for our services to work properly...
					</li>
					<li>
						...unless you are doing something illegal, in which case we can block and/or delete your stuff.
					</li>
					<li>
						We may make changes to our terms, service and/or fees from time to time. If you don’t like
						something we change, you can always stop using our service.
					</li>
					<li>
						And we should mention that you use our service at your own risk; we are not liable for any
						damages if things go wrong.
					</li>
				</ul>
			</div>
			<h1>Terms of Service</h1>
			{children}
			<div>
				December 2022
				<br />
				<br />
				Your use of and access to our services, websites (including browser extensions) and/or applications
				(together: “Services”) are governed by these Terms of Service (“Terms”).
				<br />
				<br />
				The Services may be provided to you online and/or may be integrated in a third party service.
				<br />
				<br />
				The Services allow you to upload, submit, process, store, share, receive dataset with photos
				(“Content”). You retain all rights in- and responsibility and liability for all Content.
				<br />
				<br />
				Singularis Programming does not claim ownership of your Content. The Services are provided to you as the
				user of the Services by Singularis Programming, with its main office at Zhandosova str., 1/1, Almaty,
				Kazakhstan, registered with tax id 221040004317.
			</div>
			<ol>
				<li>
					<span>Applicability</span>
					<br />
					<br />
					<ol>
						<li>You are only allowed to use the Services when aged 21 or older.</li>
						<li>
							Please read the Terms carefully. By using the Services (directly with us or through a third
							party application, plug-in, extension or integration) you agree and accept these Terms and
							our <a href={NavigatorHelper.urlToNoticeAndTakeDownPolicy}>Notice and Take Down Policy</a>{' '}
							(“NTD Policy”). To the use of personal data in relation to the Services our{' '}
							<a href={NavigatorHelper.urlToPrivacyPolicy}>Privacy Policy</a>.
						</li>
						<li>
							If the Services include, are used in connection with, or are integrated in the services of
							third parties, the terms and conditions, notice and take down policies and/or privacy
							policies of those third parties may apply in addition to these Terms. If you are using the
							Services on behalf of your employer or another organisation, you are agreeing to the terms
							of that organisation and you represent and warrant that you have the authority to do so.
							Singularis Programming is not responsible for any third party services, terms and/or
							policies.
						</li>
						<li>
							If you want to file a complaint or notice about unlawful Content being stored or shared via
							the AI Defect Detection, please read our{' '}
							<a href={NavigatorHelper.urlToNoticeAndTakeDownPolicy}>NTD Policy</a>.
						</li>
						<li>
							Singularis Programming can amend the Terms from time to time. The amended Terms will become
							effective upon them being posted on AI Defect Detection’s website or at such later date as
							may be stated on the amended Terms. Therefore, we recommend that you review the Terms from
							time to time and take note of any changes. By continuing your use of the Services you accept
							the amended Terms. In case of material changes to the Terms, you will be informed prior to
							the change: (i) at the moment you use the Services, or (ii) by a message to the contact
							details you provided to us, or (iii) by a posting of the notice of the change on AI Defect
							Detection’s website. In the event you don’t accept a change you can always stop using our
							service.
						</li>
						<li>
							These Terms supersede any and all prior oral and written quotations, terms, communications,
							agreements and understandings between you and Singularis Programming.
						</li>
					</ol>
				</li>
				<li>
					<span>AI Defect Detection Result File Sharing</span>
					<br />
					<br />
					<ol>
						<li>Link access to processing results allows you to share your Content with others. </li>
						<li>
							To share the results of processing your Content you need to upload it and provide us with
							one email address of the recipient (“email transfer”) or choose to distribute a download
							link yourself (“link transfer”).
						</li>
						<li>
							The results of processing your Content are stored on Singularis Programming’s servers for a
							limited period of time (24 hours) after which the ability to download expires.
						</li>
						<li>
							After the expiry period AI Defect Detection will permanently delete the uploaded files and
							the result of processing these files. These files will not be available nor retrievable
							anymore.
						</li>
						<li>
							The basic functionality of AI Defect Detection is (currently) free from registration and
							charge.
						</li>
						<li>
							AI Defect Detection treats Content as confidential and does not control the use of download
							links, regardless of whether they are originally distributed by us or by you. Recipients can
							forward the links and allow others to use them. You are solely responsible for the Content
							you upload and share.
						</li>
						<li>
							For additional functionality (such as the “Send link to email”) you have to provide us with
							your email address.
						</li>
					</ol>
				</li>
				<li>
					<span>Content ownership, permissions and responsibility</span>
					<br />
					<br />
					<ol>
						<li>
							Singularis Programming does not claim any ownership of the Content you upload, receive,
							store or share through the Services and you are solely responsible for it. Also you are
							solely responsible for sharing it with the correct recipients. Any liability for damages
							relating to the Content lies with the individual that uploads, uses, stores and/or shares it
							within the Services. You acknowledge that download and/or access links can be forwarded and
							that recipients having access to such links, can access the Content it’s connected with.
						</li>
						<li>
							By using the Services you warrant that you have, for any Content you upload, use, store or
							share using the Services, all required permissions (including from copyright and other
							intellectual property rights owners) to distribute, sub-license, transfer, store and/or make
							the Content online available as part of the Services.
						</li>
						<li>
							Singularis Programming is not liable to you or any third party for any damages arising out
							of or in relation to the Content uploaded, created, used, stored or shared by you within the
							Services, including but not limited to, copyright protected works and/or trademarks.
						</li>
						<li>
							Singularis Programming requires a licence from you with regards to the Content FOR THE SOLE
							PURPOSE OF OPERATING, ENABLING, AND IMPROVING THE SERVICES. Solely for this explicit
							purpose, you agree and acknowledge that by using the Services, you grant us an unlimited,
							worldwide, royalty-free licence to use, host, store, process and edit (including but not
							limited to scaling and adapting) the Content. For the avoidance of doubt, Singularis
							Programming will not sell or advertise the Content: Singularis Programming only requires the
							licence FOR THE SOLE PURPOSE OF OPERATING, ENABLING, AND IMPROVING THE SERVICES.
						</li>
						<li>
							AI Defect Detection does not provide any public search function, catalogue or listing to
							find Content.
						</li>
						<li>
							More information on the use of your personal data is available in our{' '}
							<a href={NavigatorHelper.urlToPrivacyPolicy}>Privacy Policy</a>.
						</li>
					</ol>
				</li>
				<li>
					<span>Restrictions</span>
					<br />
					<br />
					<ol>
						<li>
							Singularis Programming respects your rights and expects that you respect those of others,
							including Singularis Programming, its officers, directors, employees and third parties. This
							includes respecting the right to privacy, corporate intelligence, business secrets and
							intellectual property rights, such as trademarks, copyrights, trade names and logos. You
							agree not to use the Services to commit, promote, enable or facilitate any unlawful or
							criminal acts or breach of these Terms or facilitate or promote others to do so.
						</li>

						<li>
							As a condition to make use of the Services you agree not to upload, use, store or share any
							Content that:
						</li>
						<ul>
							<li>features CSAI (child sexual abuse imagery);</li>
							<li>
								is obscene, defamatory, libelous, slanderous, profane, indecent, discriminating,
								threatening, abusive, harmful, lewd, vulgar, or unlawful;
							</li>
							<li>promotes racism, violence or hatred;</li>
							<li>is factually inaccurate, false, misleading, misrepresenting or deceptive;</li>
							<li>you don’t hold the rights to;</li>
							<li>
								infringes, violates or misappropriates intellectual property rights, privacy rights,
								including data protection rights, and/or any other kind of rights;
							</li>
							<li>infringes on or violates any applicable law or regulation; and/or</li>
							<li>
								constitutes ‘hate speech’, whether directed at an individual or a group, and whether
								based upon the race, sex, creed, national origin, religious affiliation, sexual
								orientation, language or another characteristic of such individual or group.
							</li>
						</ul>
						<li>In addition, you agree not to:</li>
						<ul>
							<li>
								abuse, harass, stalk, intimidate, threaten, commit violence, or otherwise act unlawful,
								or encourage anyone else to do so;
							</li>
							<li>impersonate or falsely pretend affiliation with any person or entity;</li>
							<li>access any non-public areas of the Services;</li>
							<li>interfere with any access or use restrictions;</li>
							<li>
								use any data mining or data gathering or extraction methods, or otherwise collect
								information about the users of the Services;
							</li>
							<li>
								send viruses, worms, malware, ransomware, junk email, spam, chain letters, phishing
								emails, unsolicited messages, promotions or advertisements of any kind and for any
								purpose;
							</li>
							<li>interfere with, damage or disrupt the Services or act in a way that may do so;</li>
							<li>
								attempt to probe, scan, compromise or test the vulnerability of the Services or any
								related service, system or network or breach any security;
							</li>
							<li>use automated means to access or use the Services without our permission;</li>
							<li>reverse engineer or decompile any (part) of the Services;</li>
							<li>
								resell, sublicence, rent, lease, offer or otherwise commercialise the Services without
								our permission.
							</li>
						</ul>
					</ol>
				</li>
				<li>
					<span>Violation of the Terms of Service</span>
					<br />
					<br />
					<ol>
						<li>
							Singularis Programming reserves the right to investigate, provide to third parties,
							(temporarily) block and/or permanently delete from it servers, without prior notice or
							liability, any Content or to block anyone from accessing any part of the Services, when
							Singularis Programming ascertains, at its sole discretion or after receiving substantiated
							and valid complaints, that you breach these Terms or act in violation of any applicable law
							or regulation. More information is available in our{' '}
							<a href={NavigatorHelper.urlToNoticeAndTakeDownPolicy}>Notice and Take Down Policy</a>.
						</li>
					</ol>
				</li>
				<li>
					<span>Intellectual property rights</span>
					<br />
					<br />
					<ol>
						<li>
							All intellectual property rights and/or similar rights on the Services (including the
							software, AI Defect Detection content, typography, logos, trademarks, trade names, domain
							names, copyrights and patents) are vested in Singularis Programming and/or its licensors and
							you are not allowed to use, remove, modify, copy, mirror, distribute, decompile, or reverse
							engineer any of it in any way.
						</li>
						<li>
							You will always respect and observe the good name and reputation of Singularis Programming
							and ensure that your use of the Services will in no way prejudice any rights and/or the good
							name and reputation of Singularis Programming and its licensors.
						</li>
					</ol>
				</li>
				<li>
					<span>Disclaimer and account registration</span>
					<br />
					<br />
					<ol>
						<li>
							AI Defect Detection provides the Services “AS-IS”, without any warranty of any kind. Without
							limiting the foregoing, Singularis Programming explicitly disclaims any warranties of
							merchantability, fitness for a particular purpose and non-infringement. Singularis
							Programming makes no warranty that the Services are available on an uninterrupted, secure or
							error-free basis. Your use of the Services is at your own risk. You acknowledge and agree
							that Singularis Programming is not responsible for any damages to the computer system or
							mobile device of you or any third party that result from the use of the Services and is not
							responsible for any failure of the Services to store, process, transfer or delete a file or
							for the corruption or loss of any data, information or Content contained in a file.
						</li>
						<li>
							Singularis Programming may change, terminate or expand its Services from time to time and
							reserves the right to limit access to or eliminate any features or functionality of the
							Services in its own discretion.
						</li>
						<li>
							Some of the Services require you to provide us your email address. You must ensure that
							these are accurate. Singularis Programming is not liable for any loss or damage arising from
							the unauthorised use of your email address.
						</li>
					</ol>
				</li>
				<li>
					<span>Indemnity and Liability</span>
					<br />
					<br />
					<ol>
						<li>
							You will defend, indemnify and hold harmless Singularis Programming (including its employees
							and affiliates) from and against any claims, incidents, liabilities, procedures, damages,
							losses and expenses (including legal and accounting fees), arising out of or in any way
							connected with your access to or use of the Services or your breach of these Terms,
							including any third party claims that Content created, uploaded, stored or shared using the
							Services by you, infringe or violate any third party rights.
						</li>
						<li>
							The Services may provide integration with third-party services. You acknowledge that: (i)
							Singularis Programming is not responsible for any acts or omissions of such third-party
							services; (ii) that Singularis Programming is not an agent of such third-party services; and
							(iii) your use of those services is subject to any applicable terms and conditions between
							you and the providers of such services.
						</li>
						<li>
							To the extent permissible under local mandatory law, Singularis Programming is not liable
							for any damage or personal injury resulting from any use of the Services, including any
							(temporary) unavailability or (accidental) removal of your Content. The limitation of
							liability referred to in this clause shall not apply if the liability for damage caused by
							intent or gross negligence on the part of Singularis Programming.
						</li>
					</ol>
				</li>
				<li>
					<span>Waiver, Severability & Assignment</span>
					<br />
					<br />
					<ol>
						<li>
							Singularis Programming’s failure to enforce a provision is not a waiver of its right to do
							so later.
						</li>
						<li>
							If any (part of a) provision of these Terms is found to be illegal, unenforceable or
							otherwise invalid, then (i) the rest of the Terms will remain in full force and effect to
							the extent permissible under or consistent with the relevant laws; and (ii) that part will
							be deemed to be deleted and substituted by a valid one which in its economic effect comes
							closest to the invalid part.
						</li>
						<li>
							You may not assign any of your rights under these Terms. Singularis Programming is at any
							time entitled to assign its rights to any of its affiliates or subsidiaries, or to any
							successor in interest of any business associated with the Services without your consent or
							any other restriction.
						</li>
					</ol>
				</li>
				<li>
					<span>Applicable law and Jurisdiction</span>
					<br />
					<br />
					<ol>
						<li>
							These Terms and any non-contractual obligations arising out of or in connection with it will
							be governed by and construed and interpreted in accordance with law of the Republic of
							Kazakhstan, including mandatory consumer law. These Terms will not limit any consumer
							protection rights that you may be entitled to under the mandatory laws of your country of
							residence.
						</li>
						<li>
							Any disputes regarding these Terms and the use of our service will be considered in the
							jurisdiction of the Republic of Kazakhstan in the competent court at the place of
							registration of Singularis Programming.
						</li>
					</ol>
				</li>
				<li>
					<span>Contact</span>
					<br />
					<br />
					You can contact Singularis Programming at{' '}
					<a href='mailto:aidd@singularis-lab.com'>aidd@singularis-lab.com</a>. If you have any questions,
					just send us an email in English.
				</li>
			</ol>
		</>
	);
};
