import { injectable } from 'inversify';
import { action, computed, makeObservable, observable } from 'mobx';

@injectable()
export class ModalStore {
	@observable private _modalActive = false;

	constructor() {
		makeObservable(this);
	}

	@computed get modalActive(): boolean {
		return this._modalActive;
	}

	@action public setModalActive(value: boolean): void {
		this._modalActive = value;
	}
}
