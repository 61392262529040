import './errorHeader.scss';

import { ReactComponent as WarningFillIcon } from '../../img/general-icons/warningFill.svg';
import { observer } from 'mobx-react-lite';

export const ErrorHeader = observer((): JSX.Element => {
	return (
		<div className='error-header'>
			<div className='error-header__container'>
				<p className='error-header__top'>
					<WarningFillIcon />
					The connection to the server has been interrupted.{' '}
				</p>
				<p className='error-header__bottom'>
					Check your internet connection and VPN or write to us by email:{' '}
					<a href='mailto:aidd@singularis-lab.com'>aidd@singularis-lab.com</a>
				</p>
			</div>
		</div>
	);
});
