import './header.scss';

import { ReactComponent as Logo } from '../../img/logo/logo.svg';
import { observer } from 'mobx-react';
import { history } from '../../routing/history';

export const Header = observer((): JSX.Element => {
	const logoClick = (): void => {
		history.push('/');
	};

	return (
		<div className='app__header header'>
			<div className='header__container'>
				<Logo onClick={logoClick} />
			</div>
		</div>
	);
});
