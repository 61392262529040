import classNames from 'classnames';
import { ButtonHTMLAttributes } from 'react';
import './button.scss';

interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	hasActive?: boolean;
}

export const Button = ({ children, hasActive, className, ...props }: IButtonProps): JSX.Element => {
	return (
		<button {...props} className={classNames('button', { active: hasActive }, className)}>
			{children}
		</button>
	);
};
