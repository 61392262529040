import './resultDisplayModal.scss';

import { observer } from 'mobx-react-lite';
import ReactModal from 'react-modal';

import { MutableRefObject, useEffect, useRef, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { API_URL } from '../../../constantsHub/constants';
import { useInject } from '../../../hooks/useInject';
import { ReactComponent as CloseIcon } from '../../../img/general-icons/close.svg';
import { ReactComponent as FileDownloadIcon } from '../../../img/general-icons/fileDownload.svg';
import { ReactComponent as QuestionIcon } from '../../../img/general-icons/question.svg';
import { Types } from '../../../inversify/inversify.types';
import { ApiUrlFabric } from '../../../services/api/apiUrlFabric';
import { ProcessStore } from '../../../stores/processStore';
import { OpenSeaDragonViewer } from '../../openSeaDragonViewer/OpenSeaDragonViewer';
import { CustomSlider } from '../../UI/slider/CustomSlider';
import { MinusButton } from '../../UI/tools/sizeButton/minus/MinusButton';
import { PlusButton } from '../../UI/tools/sizeButton/plus/PlusButton';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';

export const ResultDisplayModal = observer((): JSX.Element => {
	const processStore = useInject<ProcessStore>(Types.ProcessStore);
	const apiUrlFabric = useInject<ApiUrlFabric>(Types.ApiUrlFabric);
	const [zoomValue, setZoomValue] = useState<number>(100);

	const thresholdRef = useRef<HTMLDivElement>(null);

	const questionRef = useRef<HTMLDivElement>(null);
	const [questionActive, setQuestionActive] = useState(false);

	const { width } = useWindowDimensions();

	const handleZoomChange = (value: number | undefined): void => {
		if (value) {
			setZoomValue(value);
		}
	};

	const customStyles = {
		content: {
			backgroundColor: '#1E1F20',
			borderRadius: width < 1200 ? '0' : '20px',
			maxWidth: '1200px',
			maxHeight: width < 1200 ? '100%' : '800px',
			width: '100%',
			height: '100%',
			top: '50%',
			left: '50%',
			transform: 'translate(-50%, -50%)',
			padding: '0',
		},
	};

	const handleQuestionTooltip = (): void => {
		if (questionRef.current) {
			if (questionActive) {
				ReactTooltip.hide(questionRef.current);
			} else {
				ReactTooltip.show(questionRef.current);
			}
			setQuestionActive(!questionActive);
		}
	};

	useEffect(() => {
		if (thresholdRef.current) {
			ReactTooltip.show(thresholdRef.current);
		}
		setTimeout((): void => {
			if (thresholdRef.current) {
				ReactTooltip.hide(thresholdRef.current);
			}
		}, 1000);
	}, [processStore.threshold]);

	return (
		<ReactModal
			isOpen={processStore._modalStore.modalActive}
			style={customStyles}
			appElement={document.getElementById('root') || undefined}
			shouldCloseOnEsc={true}
			shouldCloseOnOverlayClick={true}
			onRequestClose={(event: React.MouseEvent | React.KeyboardEvent): void => {
				processStore.handleModalClose();
			}}
		>
			<div className='result-modal'>
				<div className='result-modal__close'>
					<CloseIcon
						onClick={(): void => {
							processStore.handleModalClose();
						}}
					/>
				</div>
				<div className='result-modal__title'>{processStore.fileName}</div>
				<OpenSeaDragonViewer zoomLevelPercent={zoomValue} handleZoomChange={handleZoomChange} />
				<div className='result-modal__tools tools-result'>
					<div className='tools-result__left'>
						<div className='tools-result__left-question'>
							Defect detection threshold
							<p data-tip data-for='question' ref={questionRef} onClick={handleQuestionTooltip}>
								<QuestionIcon />
							</p>
							<ReactTooltip id='question' type='warning' effect='solid'>
								Control element for selecting the threshold value for displaying the found defects. When
								you change the threshold, those defects are displayed, the value of which is greater
								than or equal to the threshold you selected. This will help you to filter out false
								positives from the algorithm in your image.
							</ReactTooltip>
						</div>
						<div className='tools-result__left-func-tools'>
							<CustomSlider
								className='tools-result__threshold'
								min={0}
								max={10}
								renderThumb={({ ref, ...props }, state): JSX.Element => {
									return (
										<>
											<div
												data-tip
												data-for='threshold-slider-tomb-value'
												{...props}
												ref={(element): void => {
													ref(element);
													if (element) {
														(thresholdRef as MutableRefObject<HTMLDivElement>).current =
															element;
													}
												}}
											/>
											<ReactTooltip
												id='threshold-slider-tomb-value'
												effect='solid'
												delayHide={1000}
											>
												<>{state.valueNow / 10}</>
											</ReactTooltip>
										</>
									);
								}}
								onChange={(value: number): void => {
									processStore.setThreshold(value / 10);
								}}
								value={processStore.threshold * 10}
								dashCount={10}
								textSign={[0, 0.2, 0.4, 0.6, 0.8, 1]}
							/>

							<a
								data-tip
								data-for='file-download'
								href={`${API_URL}${apiUrlFabric.downloadImage(
									processStore.taskId,
									processStore.threshold,
								)}`}
								download={processStore.fileName?.replace('zip', 'png')}
							>
								<FileDownloadIcon />
							</a>
							{width > 530 && (
								<ReactTooltip id='file-download' type='warning' effect='solid'>
									You can download the resulting image with the threshold you choose
								</ReactTooltip>
							)}
						</div>
					</div>
					<div className='tools-result__right'>
						<PlusButton id='zoom-in' />
						<MinusButton id='zoom-out' />
						<CustomSlider
							className='tools-result__zoom-slider'
							min={0}
							max={95}
							value={100 - zoomValue}
							onChange={(value: number): void => {
								handleZoomChange(100 - value);
							}}
							dashCount={1}
							textSign={['min', 'max']}
						/>
					</div>
				</div>
			</div>
		</ReactModal>
	);
});
