import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { NavigatorHelper } from '../../helpers/navigatorHelper';
import {
	Accordion,
	AccordionItem,
	AccordionItemButton,
	AccordionItemHeading,
	AccordionItemPanel,
} from 'react-accessible-accordion';

import { ReactComponent as ArrowDownIcon } from '../../img/general-icons/arrow-down.svg';

import './navigator.scss';

interface INavigatorProps {
	isNumList: boolean;
}

interface INavigatorItem {
	text: string;
	dataLink: string;
	isLink: boolean;
}

interface INavigatorItemWitchChildren extends INavigatorItem {
	children: INavigatorItem[];
}

export const Navigator = observer(({ isNumList, ...props }: INavigatorProps): JSX.Element => {
	const [navigatorItemList, setNavigatorItemList] = useState<INavigatorItemWitchChildren[]>([]);

	const tagSearchName = isNumList ? 'span' : 'h3,h4';

	const navigateHandle = (dataLink: string): void => {
		const elem = NavigatorHelper.convertCollectionToArray(document.querySelectorAll(`[data-test=${dataLink}]`))[0];
		window.scrollTo(0, elem.offsetTop);
	};

	const convertArrayToIObject = (data: HTMLElement[]): INavigatorItemWitchChildren[] => {
		const res: INavigatorItemWitchChildren[] = [];
		let indx = 0;

		for (const dataItem of data) {
			const { tagName, innerHTML } = dataItem;

			const newInnerHTML = innerHTML.replace('&amp;', '&');

			const dataLink = `navigator-agreement-${indx}`;
			dataItem.setAttribute('data-test', dataLink);

			if (tagName.toLowerCase() === 'span') {
				res.push({
					text: newInnerHTML,
					dataLink: dataLink,
					isLink: true,
					children: [],
				});
			} else if (tagName.toLowerCase() === 'h3') {
				res.push({
					text: newInnerHTML,
					dataLink: dataLink,
					isLink: false,
					children: [],
				});
			} else if (tagName.toLowerCase() === 'h4') {
				res[res.length - 1].children.push({
					text: newInnerHTML,
					dataLink: dataLink,
					isLink: false,
				});
			}
			indx++;
		}

		res.forEach((item) => {
			if (!item.children.length) {
				item.isLink = true;
			}
		});

		return res;
	};

	const renderList = (): JSX.Element => {
		return (
			<>
				{navigatorItemList.map((item, indx) => {
					return item.isLink ? (
						<li
							key={item.text}
							onClick={(): void => {
								navigateHandle(item.dataLink);
							}}
						>
							{item.text}
						</li>
					) : (
						<AccordionItem key={item.text}>
							<AccordionItemHeading>
								<AccordionItemButton>
									<div>{item.text}</div>
									<ArrowDownIcon />
								</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<ul>
									{item.children.map((childrenItem, childrenIndex) => {
										return (
											<li
												key={childrenItem.text}
												onClick={(): void => {
													navigateHandle(childrenItem.dataLink);
												}}
											>
												{childrenItem.text}
											</li>
										);
									})}
								</ul>
							</AccordionItemPanel>
						</AccordionItem>
					);
				})}
			</>
		);
	};

	useEffect(() => {
		const array = NavigatorHelper.convertCollectionToArray(document.querySelectorAll(tagSearchName));
		const iObjectList = convertArrayToIObject(array);

		setNavigatorItemList(iObjectList);
	}, []);

	return (
		<div className='navigator'>
			<Accordion allowZeroExpanded allowMultipleExpanded>
				{isNumList ? <ol>{renderList()}</ol> : <ul>{renderList()}</ul>}
			</Accordion>
		</div>
	);
});
