import { HtmlHTMLAttributes } from 'react';
import { NavigatorHelper } from '../../../../helpers/navigatorHelper';

export const PrivacyPolicy = ({ children, ...props }: HtmlHTMLAttributes<HTMLDivElement>): JSX.Element => {
	return (
		<>
			<h1>Privacy Policy </h1>
			{children}
			<div>
				Since day one, <b>Singularis Programming</b> has cared about privacy and respecting our users.
				Protecting the data of our users is of the highest importance to us, and we go to great lengths to make
				sure we keep it safe.
			</div>
			<div>
				We take your trust and right to privacy seriously and work continuously to keep your digital footprint
				small and in your control. Nevertheless, so that you can receive notifications about the end of
				processing your archive, we need to process some personal information. For privacy-related questions,
				please reach out to our Data Protection Officer via{' '}
				<a href='mailto:aidd@singularis-lab.com'>aidd@singularis-lab.com</a>.
			</div>
			<div>
				This statement covers the processing activities of the AI Defect Detection services, websites and/or
				applications (together: “Services”). The Services allow you to upload, submit, process, store, share,
				receive dataset with photos (“Content”). The Services may be provided to you online and/or may be
				integrated in a third party service. By using any of the Services you agree to have read and understood
				our Privacy Policy as below.
			</div>
			<h3>What information do we collect?</h3>
			We store some personal information to make your use of our Services possible. There are several ways in
			which we may collect personal information from you: you may submit it or we will receive from partners.
			Different types of personal information may be collected depending on the way you use our Services and which
			Services you use. Please check the section “What are your rights as a user” to find out how you can change,
			access or delete your personal information.
			<h4>Personal information you submit</h4>
			<div>
				AI Defect Detection collects, processes or hosts personal information you provide.
				<ol>
					<li>
						<b>Contact information</b>: your email address or the email address of the recipient.
					</li>
					<li>
						<b>Content and metadata</b>: you may choose to upload Content which contains all sorts of
						personal information about you and others. Such Content also contains a filename, size and
						filetype.
					</li>
				</ol>
				The uploader is responsible for the legitimacy of the Content and needs to follow our{' '}
				<a href={NavigatorHelper.urlToTemsOfServicePage}>Terms of Service</a>, AI Defect Detection only
				processes it to provide its Services. If you upload, send personal information via our Service, you may
				ask for a Data Processing Agreement by sending an email to{' '}
				<a href='mailto:aidd@singularis-lab.com'>aidd@singularis-lab.com</a>.
			</div>
			<h4>Personal information we receive from partners</h4>
			<div>
				In some cases we receive personal information from third parties, such as <b>Legal Information</b>: when
				law enforcement agencies or courts order us to take down Content, we may initially receive personal
				information about you and your behaviour.
			</div>
			<h3>Why do we use your personal information?</h3>
			<div>
				We use your personal information in order to provide and improve our Services, to comply with legal
				obligations and to keep our Services safe and secure. Please find below how we use your personal
				information specifically and on what legal grounds we base the use of your personal information.
			</div>
			<h4>Activities & purposes</h4>
			<div>
				<ol>
					<li>
						<b>Service</b>: the most important reason for using your personal information is, of course, to
						offer you our Services as mentioned in our{' '}
						<a href={NavigatorHelper.urlToTemsOfServicePage}>Terms of Service</a>, for example to receive
						notifications about the end of processing your archive and to let you access and use your
						Content across different devices.
					</li>
					<li>
						<b>Support</b>: we provide a wide range of support services to help you out whenever you’re in
						need, for instance when you need technical assistance. If it’s needed to offer support, the
						Support-team can, on your request, access your Content in order to help you out.
					</li>
					<li>
						<b>Safety, integrity & security</b>: Singularis Programming follows up on abuse reports, NTD
						reports, fraud investigations and could investigate your compliance with our{' '}
						<a href={NavigatorHelper.urlToTemsOfServicePage}>Terms of Service</a>. Furthermore, we detect &
						block Child Sexual Abuse Imagery (CSAI). Cases of potential CSAI will be reported directly to
						law enforcement agencies. Furthermore, we protect ourselves against fraud and other illegal
						activities. In all these cases we withhold the right to preserve your Content and share it,
						together with other identifying information, with law enforcement agencies. Finally, we could
						use your personal information for internal control to safeguard our and your safety, integrity
						and security. For instance, in case of any suspicion of violations of our{' '}
						<a href={NavigatorHelper.urlToTemsOfServicePage}>Terms of Service</a>.
					</li>
					<li>
						<b>Improvement & development</b>: we evaluate the use of our Services to improve our Services,
						fix bugs, develop new products and services.
					</li>
					<li>
						<b>Legal</b>: in so far as necessary, we might use your personal information to defend
						Singularis Programming in legal proceedings in relation to or as a result of your use of our
						Services, following a court order, abiding by any law, regulation or governmental request,
						cooperating with law enforcement, cooperating in fraud investigations of third parties,
						safeguarding national security, defense, public security, and to uphold our Terms of Service.
					</li>
				</ol>
			</div>
			<h4>Legal grounds</h4>
			<div>
				<ol>
					<div>Each processing activity has a valid legal ground, which is described below.</div>
					<li>
						<b>Contractual obligations with you</b>: regarding the activities and purposes mentioned under 1
						and 2. We need to process personal information to offer our Services through our websites, to
						provide (technical) support.
					</li>
					<li>
						<b>Legal obligations</b>: regarding the activities and purposes under 3 and 5. We’re legally
						obliged to process your personal information for accounting purposes, to respond to legal
						requests and NTD-requests.
					</li>
					<li>
						<b>Consent</b>: (partially) regarding activities and purposes mentioned under 2 (e.g. accessing
						your Content for support).
					</li>
					<li>
						<b>Legitimate interests</b>: (partially) regarding activities and purposes under 1 (e.g. to
						provide cross device access). For the purposes mentioned under 3 in order to provide safe
						Services, to prevent fraud and react against illegal use of our Services. For our innovative
						interests as mentioned under 4. And finally for legal & compliance interests as stated under 5.
						When we use your personal information based on our or a third party’s legitimate interest, we
						will make sure to balance your rights and freedoms against said legitimate interest. If, to the
						extent applicable, you wish to object to the activities based on our legitimate interest and
						there’s no opt-out available in your account settings or received communication, please contact{' '}
						<a href='mailto:aidd@singularis-lab.com'>aidd@singularis-lab.com</a>.
					</li>
				</ol>
			</div>
			<h3>What parties do we share personal information with?</h3>
			<div>
				AI Defect Detection has several partners to operate and improve its Services. Furthermore, we may allow
				you to share your information as part of some of the Services.
			</div>
			<div>
				<ol>
					<li>
						<b>Service providers</b>: such as our hosting provider, user support provider, IT & software
						providers, email processors and the accountant.
					</li>
					<li>
						<b>People of your choice</b>: you may choose to share your Content with others within our
						Services.
					</li>
					<li>
						<b>Law enforcement agencies or regulators</b>: we are obliged to share your personal information
						in case of a legal request. In case we run across CSAI or when we’re notified on other illegal
						Content we’ll also share your personal information with law enforcement agencies.
					</li>
					<li>
						<b>Singularis Programming entities</b>: we share personal information between entities which are
						part of the Singularis Programming in order to provide our Services and for all purposes
						mentioned under “Why do we use your personal information?”.
					</li>
				</ol>
			</div>
			<div>
				We are a global business, which means we may transfer or store personal information in countries with
				different data protection laws. However, when we transfer and host data around the world, we will ensure
				that security measures are in place for the appropriate user jurisdictions.
			</div>
			<h3>Can you use AI Defect Detection for private or sensitive files?</h3>
			<div>
				We treat your Content with respect. We don’t provide any public search function, catalogue or listing to
				find Content. We’re obliged to delete your Content when we receive a Notice-and-takedown-request or when
				we receive a legal request.
			</div>
			<div>
				Please be mindful when distributing download/ access links - whoever it is passed on to or has access to
				a download/access link can access or download the Content.
			</div>
			<h3>What retention procedures are in place?</h3>
			<div>
				The retention period for your personal information depends on which Service you use and it depends on
				which category of personal information we’re talking about. Always check out the website of the Service
				for the specific information on retention periods.
			</div>
			<h4>Personal information</h4>
			<div>
				AI Defect Detection retains your personal information as long as its necessary to provide our Services
				to you, to conduct our business activities and fulfill our legitimate interests, such as providing safe
				and secure services, to fix bugs and to reach out to you, to comply with applicable laws and legal
				requests and to resolve (legal) disputes.
			</div>
			<h4>Content you upload, process, store or share through the Services</h4>
			<div>
				When using AI Defect Detection your Content will be deleted after 24 hours. After this period, a file is
				automatically deleted from our servers. The personal information that accompanies your processing is
				kept for a maximum of 12 months. Such personal information is only accessible to very few people within
				Singularis Programming, those that need it to perform their job, for example to provide you with
				support.
			</div>
			<h3>How safe is it to use our Services?</h3>
			<div>
				Singularis Programming takes technical and organisational measures to protect your personal information
				against loss or other forms of unlawful processing.
			</div>
			<div>
				We make sure that personal information is only accessible by those who need access to do their job and
				that they are properly authorised. That means we keep logs of who has access to personal information, we
				limit the amount of people that have access and we make sure that personal information can only be read,
				copied, modified or removed by properly authorised staff. We monitor internal activity to ensure the
				safety and accuracy of personal information.
			</div>
			<div>
				Singularis Programming staff is required to conduct themselves in a manner consistent with the company’s
				guidelines regarding confidentiality, ethics, and appropriate usage of personal information. Staff is
				required to sign a confidentiality agreement.
			</div>
			<div>
				During an upload, while it&apos;s stored on our servers and during a download, Content is encrypted and
				only sent over a secure connection (https). The servers we use to store your Content for you are
				Republic of Kazakhstan law and GDPR compliant and secure.
			</div>
			<h3>Can minors use our Services?</h3>
			<div>
				You are only allowed to use our website, apps and/or Services when aged 16 and over. When you’re younger
				than 16 you may use our website, apps and/or Services only after parental approval or approval of your
				legal representative. Minors under the age of 13 in the USA are not allowed to use our Services.
			</div>
			<h3>What are your rights as a user?</h3>
			<div>
				If you need more info on your personal information, please let us know. We may ask for proof of
				identity. If you change your mind and no longer want us to process your personal information, let us
				know.
			</div>
			<div>You can ask Singularis Programming to:</div>
			<div>
				<ol>
					<li>access, receive a copy of or correct your personal information;</li>
					<li>in certain cases, erase your personal information or block or restrict our use of it;</li>
					<li>in certain cases, send your personal information to other third parties.</li>
				</ol>
			</div>
			<div>
				You can do this by sending an email to{' '}
				<a href='mailto:aidd@singularis-lab.com'>aidd@singularis-lab.com</a>. Please state clearly in the
				subject that your request concerns a privacy matter and more specific whether it is a request to access,
				correction or deletion. Bear in mind that under circumstances Singularis Programming requests for
				additional information to determine your identity.
			</div>
			<h4>Right to object</h4>
			<div>
				You have a right to object to our use of your personal information, for instance when the legal base for
				processing is based on one of our legitimate interests (see subsection “Legal grounds”). If you inform
				us that you do not longer wish us to process your personal information or to be approached, Singularis
				Programming will move your personal information to a separate file. Your personal information will no
				longer be used for the above mentioned purposes, unless our legitimate interest, for example safety &
				security, outweighs your right to objection. You can request this via{' '}
				<a href='mailto:aidd@singularis-lab.com'>aidd@singularis-lab.com</a>. Please state clearly that your
				request concerns a privacy matter and more specifically that you exercise your right to object.
			</div>
			<div>
				If you think we have infringed your privacy rights, you can lodge a complaint with the relevant
				supervisory authority. You can lodge your complaint in particular in the country where you live, your
				place of work or place where you believe we infringed your right(s).
			</div>
			<h4>Withdrawal of consent</h4>
			<div>
				When you’ve provided your consent for us to process your personal information, you can withdraw your
				consent at any time, without affecting the lawfulness of processing activities based on consent before
				its withdrawal. If you withdraw your consent, we will no longer process the personal information which
				we’ve received based on your consent.
			</div>
			<h4>California consumers</h4>
			<div>
				Subject to certain limitations under California Civil Code § 1798.83, if you are a California resident,
				you may ask us to provide you with (i) a list of certain categories of personal information that we have
				disclosed to certain third parties for their direct marketing purposes during the immediately preceding
				calendar year and (ii) the identity of certain third parties that received personal information from us
				for their direct marketing purposes during that calendar year. We do not, however, share your personal
				information with marketers, subject to the above statute.
			</div>
			<h3>How to contact Singularis Programming</h3>
			<div>
				Our support team is available via <a href='mailto:aidd@singularis-lab.com'>aidd@singularis-lab.com</a>.
				If you have any questions, please email us in English, to make sure we can help you in the best way. If
				you have questions about the way AI Defect Detection processes your personal information or the personal
				information AI Defect Detection stores about you, please contact Singularis Programming by sending an
				email with your question to <a href='mailto:aidd@singularis-lab.com'>aidd@singularis-lab.com</a>. If you
				need to contact our Data Protection Officer, please do so via{' '}
				<a href='mailto:aidd@singularis-lab.com'>aidd@singularis-lab.com</a>.
			</div>
			<h3>Revisions to the Privacy Policy</h3>
			<div>
				Note that Singularis Programming may revise this Privacy Policy from time to time. Each revised version
				shall be dated and posted on the website. Singularis Programming recommends that you review the website
				from time to time and take note of any changes. If you do not agree with the Privacy Policy, you should
				not or no longer access or use the website and/or service. By continuing to use the website and/or
				service you accept any changes made to the Privacy Policy.
			</div>
		</>
	);
};
