import { IValidationMessageDTO } from '../dto/IValidationMessageDTO';

export class ErrorHelper {
	public static compileError(Message: string, PropertyName: string): IValidationMessageDTO {
		const error: IValidationMessageDTO = {
			Message,
			PropertyName,
		};
		return error;
	}
}
