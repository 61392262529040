import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { TaskStateConstants } from '../../../constantsHub/taskStateConstants';
import { ETaskState } from '../../../dto/ETaskState';

import { TaskResultState } from '../../../dto/TaskResultState';
import { useInject } from '../../../hooks/useInject';
import { Types } from '../../../inversify/inversify.types';
import { ProcessStore } from '../../../stores/processStore';
import './processLine.scss';

export const ProcessLine = observer((): JSX.Element => {
	const processStore = useInject<ProcessStore>(Types.ProcessStore);

	const valueKeys = Object.keys(ETaskState).filter((item) => {
		return isNaN(Number(item));
	});

	const getStatusByConsiderValue = (considerValue: string): string => {
		const enumConsiderValue = ETaskState[considerValue as keyof typeof ETaskState];
		let res = '';

		if (
			enumConsiderValue === ETaskState.Completed &&
			processStore.taskResultState === TaskResultState.CompletedWithWarning
		) {
			res = TaskResultState[TaskResultState.Completed];
		} else if (
			enumConsiderValue === ETaskState.CombiningImages &&
			processStore.taskResultState === TaskResultState.CompletedWithWarning
		) {
			res = TaskResultState[TaskResultState.CompletedWithWarning];
		} else if (processStore.taskState !== undefined && enumConsiderValue < processStore.taskState) {
			res = TaskResultState[TaskResultState.Completed];
		} else if (
			processStore.taskState !== undefined &&
			processStore.taskResultState !== undefined &&
			enumConsiderValue === processStore.taskState
		) {
			res = TaskResultState[processStore.taskResultState];
		}

		return res;
	};

	return (
		<div className='process-line'>
			{valueKeys.map((value) => {
				return (
					<div className={classNames('process-line__item', getStatusByConsiderValue(value))} key={value}>
						<div className={classNames('process-line__item-circle')}>
							{
								TaskStateConstants.stateStatusList.get(
									TaskResultState[getStatusByConsiderValue(value) as keyof typeof TaskResultState],
								)?.icon
							}
						</div>
						<div className='process-line__item-text'>
							{TaskStateConstants.taskStateProcessLineText.get(
								ETaskState[value as keyof typeof ETaskState],
							)}
						</div>
					</div>
				);
			})}
		</div>
	);
});
