export class MathHelper {
	public static getValuePercentInRange(value: number, minValue: number, maxValue: number): number {
		const { mult, diffMinValue, max } = this.getPercentParams(minValue, maxValue);
		const currentValuePercent = Math.round((1 / value) * 100);
		let res = 0;
		if (currentValuePercent <= 100) {
			res = currentValuePercent * mult;
		} else {
			res = currentValuePercent + diffMinValue;
		}
		res = Math.round((res / max) * 100);
		return 100 - res;
	}

	public static getValueInRangOfPercent(value: number, minValue: number, maxValue: number): number {
		const { mult, diffMinValue, max } = this.getPercentParams(minValue, maxValue);
		const currentValuePercent = (value / 100) * max;
		let res = 0;
		if (currentValuePercent <= diffMinValue) {
			res = currentValuePercent / mult;
		} else {
			res = currentValuePercent - diffMinValue;
			if (res < 100) {
				res = 100;
			}
		}
		res = 1 / (res / 100);
		res = res > maxValue ? res : res < minValue ? minValue : res;
		return res;
	}

	private static getPercentParams(
		minValue: number,
		maxValue: number,
	): { mult: number; diffMinValue: number; max: number } {
		const minValuePercent = Math.round((1 / minValue) * 100);
		const maxValuePercent = Math.round((1 / maxValue) * 100);
		const diffMinValue = minValuePercent - 100;
		const diffMaxValue = 100 - maxValuePercent;
		const max = diffMinValue * 2;
		const mult = diffMinValue / diffMaxValue;

		return { mult, diffMinValue, max };
	}
}
