import './uploadManager.scss';

import { observer } from 'mobx-react-lite';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import { useInject } from '../../../hooks/useInject';
import { Types } from '../../../inversify/inversify.types';
import UploadStore from '../../../stores/uploadStore';
import { Button } from '../../UI/button/Button';
import { DropZone } from '../dropZone/DropZone';
import { ReactComponent as ArrowSvg } from '../../../img/general-icons/arrow.svg';
import { UploadZone } from '../uploadZone/UploadZone';
import { ProgressStore } from '../../../stores/progressStore';
import { SamplesZone } from '../samplesZone/SamplesZone';
import { useEffect } from 'react';
import { ErrorStore } from '../../../stores/errorStore';

import { ReactComponent as ErrorIcon } from '../../../img/general-icons/error.svg';
import { ErrorFieldNameConstants } from '../../../constantsHub/errorFiledNameConstants';

export const UploadManager = observer((): JSX.Element => {
	const uploadStore = useInject<UploadStore>(Types.UploadStore);
	const progressStore = useInject<ProgressStore>(Types.ProgressStore);
	const errorStore = useInject<ErrorStore>(Types.ErrorStore);

	const handleStart = (): void => {
		const fileId = uploadStore.fileId;
		const email = uploadStore.email;
		if (fileId) {
			progressStore.createTask(fileId, email);
		}
	};

	const handleTabChange = (index: number, last: number, event: Event): boolean | void => {
		uploadStore.removeFile();
		if (index === 1) {
			uploadStore.setFileName('samples');
		}
	};

	useEffect(() => {
		uploadStore.removeFile();
	}, []);

	return (
		<div className='react-tabs__box'>
			<Tabs onSelect={handleTabChange}>
				<TabList>
					<Tab>Add a file</Tab>
					<Tab>Use sample</Tab>
				</TabList>

				<TabPanel>
					<div className='react-tabs__tab-panel-zone'>
						{uploadStore.fileLoadTotal ? <UploadZone /> : <DropZone />}
					</div>
				</TabPanel>
				<TabPanel>
					<SamplesZone />
				</TabPanel>
			</Tabs>
			{errorStore.getErrorListByName(ErrorFieldNameConstants.fileError)?.map((item, indx) => {
				return (
					<p className={'react-tabs__error'} key={`${item.PropertyName}-error-${indx}`}>
						<ErrorIcon />
						{item.Message}
					</p>
				);
			})}
			{uploadStore.fileName ? (
				<div className='react-tabs__button-box'>
					<Button hasActive={!!uploadStore.fileId} onClick={handleStart}>
						Start
						<ArrowSvg />
					</Button>
				</div>
			) : (
				''
			)}
		</div>
	);
});
