import classNames from 'classnames';
import ReactSlider, { ReactSliderProps } from 'react-slider';
import styles from './customSlider.module.css';

interface ICustomSliderProps extends ReactSliderProps {
	dashCount?: number;
	textSign?: number[] | string[];
}

export const CustomSlider = ({ className, dashCount, textSign, ...props }: ICustomSliderProps): JSX.Element => {
	const createDashList = (count: number): number[] => {
		const res = [];
		for (let value = 0; value <= count; value++) {
			res.push(value / count);
		}

		return res;
	};

	return (
		<div className={classNames(className, styles.box)}>
			<ReactSlider
				className={classNames(`${className}-slider`, styles.slider)}
				thumbClassName={classNames(`${className}-slider-tomb`, styles.tomb)}
				trackClassName={classNames(`${className}-slider-track`, styles.track)}
				{...props}
			/>
			<div className={classNames(`${className}-text`, styles.text)}>
				{dashCount &&
					createDashList(dashCount).map(
						(dashValue): JSX.Element => (
							<div
								className={classNames(`${className}-text-dash`, styles.dash)}
								key={`${className}-text-dash-${dashValue}`}
								style={{ left: `${(200 - 16) * dashValue + 8}px` }}
							/>
						),
					)}
				{textSign?.map(
					(value): JSX.Element => (
						<div key={`${className}-text-${value}`}>{value}</div>
					),
				)}
			</div>
		</div>
	);
};
