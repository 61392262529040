import './pageTitle.scss';

import { observer } from 'mobx-react';
import { useInject } from '../../../hooks/useInject';
import { Types } from '../../../inversify/inversify.types';
import { GeneralStore } from '../../../stores/generalStore';

export const Title = observer((): JSX.Element => {
	const generalStore = useInject<GeneralStore>(Types.GeneralStore);

	return <div className='body-app__title'>{generalStore.pageTitle}</div>;
});
