import { injectable } from 'inversify';
import { action, computed, makeObservable, observable } from 'mobx';

@injectable()
export class GeneralStore {
	@observable private _pageTitle = '';

	constructor() {
		makeObservable(this);
	}

	@computed public get pageTitle(): string {
		return this._pageTitle;
	}

	@action public setPageTitle(value: string): void {
		this._pageTitle = value;
	}
}
