export class SizeTypeHelper {
	public static getSizeType(value: number): string {
		const types = ['Bytes', 'Kb', 'Mb', 'Gb', 'Tb'];
		let currentValue = value;
		let typeIndex = 0;
		while (currentValue > 1024) {
			currentValue = Math.floor(currentValue / 1024);
			typeIndex += 1;
		}
		return types[typeIndex];
	}

	public static getRoundSize(value: number): number {
		let currentValue = value;
		while (currentValue > 1024) {
			currentValue = Math.floor(currentValue / 1024);
		}
		return currentValue;
	}
}
