import './errorPage.scss';

import { history } from '../../routing/history';
import windGenerators from '../../img/big-wind-generators.png';

export const ErrorPage = (): JSX.Element => {
	const goToMainPage = (): void => {
		history.push('/');
	};

	return (
		<div className='error-page'>
			<div className='error-page__content'>
				<div className='error-page__title'>Oops!</div>
				<div className='error-page__subtitle'>We can’t seem to find the page you’re looking for.</div>
				<div className='error-page__code'>Error code: 404</div>
				<div className='error-page__text'>
					<p>
						If you follow this link to view the processing result after 24 hours, then the files are deleted
						from the system.
					</p>
					<p>
						If you are sure that 24 hours have not passed since the completion of processing, then write to
						us by email: <a href='mailto:aidd@singularis-lab.com'>aidd@singularis-lab.com</a>
					</p>
					<br />
					<br />
					<p>
						You can go to the <span onClick={goToMainPage}>start page</span> to upload the archive
					</p>
				</div>
			</div>
			<div className='error-page__wind-generators'>
				<img src={windGenerators} alt='big-wind-generators' />
			</div>
		</div>
	);
};
