import './footer.scss';

import { ReactComponent as Logo } from '../../img/logo/logo-footer.svg';
import { ReactComponent as FooterIconBG } from '../../img/logo/footer-icon-bg.svg';
import { NavigateLinks } from '../../routing/navigateLinks';
import { observer } from 'mobx-react';
import { history } from '../../routing/history';

export const Footer = observer((): JSX.Element => {
	const currentYear = new Date().getFullYear();

	const logoClick = (): void => {
		history.push('/');
	};

	return (
		<div className='app__footer footer'>
			<div className='footer__container'>
				<div className='footer__left left-footer'>
					<div className='left-footer__logo'>
						<Logo onClick={logoClick} />
						<div className='left-footer__logo-text'>
							software development,
							<br />
							computer vision, and robotics
						</div>
					</div>

					<div className='left-footer__desc'>
						<p>© 2009 - {currentYear} Singularis Lab Ltd. All rights reserved.</p>
						<p>Singularis Lab - software development, computer vision and robotics.</p>
					</div>
				</div>
				<div className='footer__right right-footer'>
					{NavigateLinks.socialLinks.map((item) => (
						<a href={item.path} key={item.path}>
							<FooterIconBG />
							<item.icon />
						</a>
					))}
				</div>
			</div>
		</div>
	);
});
