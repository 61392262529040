import { injectable } from 'inversify';
import { makeAutoObservable } from 'mobx';

interface IEventSubscriberData<EventData> {
	Add(handler: { (data?: EventData): void }): void;
	Delete(handler: { (data?: EventData): void }): void;
	TriggerEvent(data?: EventData): void;
}

@injectable()
export class EventSubscriber<EventData> implements IEventSubscriberData<EventData> {
	private _method: ((data?: EventData | undefined) => void) | undefined;

	constructor() {
		makeAutoObservable(this);
	}

	Add(handler: (data?: EventData | undefined) => void): void {
		this._method = handler;
	}

	Delete(handler: (data?: EventData | undefined) => void): void {
		this._method = undefined;
	}

	TriggerEvent(data?: EventData | undefined): void {
		if (this._method) {
			this._method(data);
		}
	}
}
