import { Either, left, right } from '@sweet-monads/either';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { inject, injectable } from 'inversify';
import { API_URL } from '../../../constantsHub/constants';
import { ErrorFieldNameConstants } from '../../../constantsHub/errorFiledNameConstants';
import { ErrorHelper } from '../../../helpers/errorHelper';
import { Types } from '../../../inversify/inversify.types';
import { ErrorStore } from '../../../stores/errorStore';
import { ApiUrlFabric } from '../apiUrlFabric';
import { BaseApi, RequestData } from './baseApi';

@injectable()
export class AxiosApi extends BaseApi<AxiosRequestConfig> {
	@inject(Types.ApiUrlFabric) protected _apiUrlFabric!: ApiUrlFabric;
	@inject(Types.ErrorStore) protected _errorStore!: ErrorStore;

	private $api = axios.create({ baseURL: API_URL });

	constructor() {
		super();

		this.$api.interceptors.request.use(
			(config) => this._interceptOnFulfilled(config),
			(e) => this._interceptOnReject(e),
		);
	}

	protected _doApiRequest = async <TBody, TError>(
		apiRequest: Promise<AxiosResponse<TBody>>,
	): Promise<Either<AxiosResponse<TError>, TBody>> => {
		try {
			const response = await apiRequest;
			return right(response.data);
		} catch (e: any) {
			console.log(e);
			if (e.response.status === 500) {
				this._errorStore.setErrors([ErrorHelper.compileError('', ErrorFieldNameConstants.connectionError)]);
			}
			return left(e.response);
		}
	};

	protected _get = <T, R = AxiosResponse<T>>(data: RequestData<AxiosRequestConfig>): Promise<R> => {
		return this.$api.get<T, R>(data.url, data.config);
	};

	protected _post = <T, R = AxiosResponse<T>>(data: RequestData<AxiosRequestConfig>): Promise<R> => {
		return this.$api.post<T, R>(data.url, data.payload, data.config);
	};

	protected _put = <T, R = AxiosResponse<T>>(data: RequestData<AxiosRequestConfig>): Promise<R> => {
		return this.$api.put<T, R>(data.url, data.payload, data.config);
	};

	protected _delete = <T, R = AxiosResponse<T>>(data: RequestData<AxiosRequestConfig>): Promise<R> => {
		return this.$api.delete<T, R>(data.url);
	};

	private _interceptOnFulfilled(config: AxiosRequestConfig): AxiosRequestConfig {
		return config;
	}

	private _interceptOnReject(error: unknown): Promise<never> {
		console.error(error);
		return Promise.reject(error);
	}
}
