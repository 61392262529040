import { Either } from '@sweet-monads/either';
import { injectable } from 'inversify';
import { IDefectsListDTO } from '../../dto/IDefectsListDTO';
import { ITaskQueueDTO } from '../../dto/ITaskQueueDTO';
import { ITaskViewDTO } from '../../dto/ITaskViewDTO';
import { AxiosApi } from './base/axiosApi';

@injectable()
export class ProcessService extends AxiosApi {
	public async getQueueNumber(id: string): Promise<Either<unknown, ITaskQueueDTO>> {
		const req = this._get<ITaskQueueDTO>({ url: this._apiUrlFabric.taskInQueue(id) });
		return this._doApiRequest(req);
	}

	public async getTaskState(id: string): Promise<Either<unknown, ITaskViewDTO>> {
		const req = this._get<ITaskViewDTO>({ url: this._apiUrlFabric.taskWithId(id) });
		return this._doApiRequest(req);
	}

	public async getTaskDefects(id: string): Promise<Either<unknown, IDefectsListDTO>> {
		const req = this._get<IDefectsListDTO>({ url: this._apiUrlFabric.taskDefects(id) });
		return this._doApiRequest(req);
	}
}
