import { HTMLAttributes } from 'react';
import '../sizeButton.scss';
import './minusButton.scss';

export const MinusButton = ({ ...props }: HTMLAttributes<HTMLDivElement>): JSX.Element => {
	return (
		<div className='size-button__minus size-button' {...props}>
			<span className='size-button__minus-horizontal'></span>
		</div>
	);
};
