import './processPage.scss';

import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { ResultDisplayModal } from '../../components/modals/resultDisplay/ResultDisplayModal';
import { FileProcessing } from '../../components/process/fileProcessing/FileProcessing';
import { useInject } from '../../hooks/useInject';
import windGenerators from '../../img/wind-generators.png';
import { Types } from '../../inversify/inversify.types';
import { GeneralStore } from '../../stores/generalStore';
import { ProcessStore } from '../../stores/processStore';

export const ProcessPage = observer((): JSX.Element => {
	const generalStore = useInject<GeneralStore>(Types.GeneralStore);
	const processStore = useInject<ProcessStore>(Types.ProcessStore);

	useEffect(() => {
		generalStore.setPageTitle('Processing results');
	}, []);

	return (
		<div className='process-page__body'>
			<div className='process-page__content'>
				<FileProcessing />
			</div>
			<div className='process-page__description description-process'>
				<div className='description-process__item'>
					<div className='description-process__title'>1. Image Stitching</div>
					<div className='description-process__text'>
						A mask is automatically created for each image using a trained convolutional neural network.
						Feature points with descriptors are computed. They are matched using k-nearest neighbors with
						several filtering heuristics to eliminate bad matches. All image contours are mapped into common
						coordinate space. For contours, a bounding box of minimum area is found to make up the final
						image.
					</div>
				</div>
				<div className='description-process__item'>
					<div className='description-process__title'>2. Defect detection</div>
					<div className='description-process__text'>
						Each image is transformed into a feature space, where areas with an abnormal surface appearance
						are highlighted using anomaly detection methods. For surface areas, maps are built, on which
						areas that require the attention of an expert are highlighted. The resulting maps go through the
						stitching stage along with the original photographs to build a general map.
					</div>
				</div>
				<div className='description-process__item'>
					<div className='description-process__title'>3. Result of processing</div>
					<div className='description-process__text'>
						<ul>
							<li>Stitched blade photo (JPEG) </li>
							<li>Heatmap (JPEG) </li>
							<li>Stitched photograph of a blade (JPEG) marked with rectangles with possible defects </li>
							<li>File with the list of coordinates of probable defects (XLSX)</li>
							<li>Imaging report (XLSX)</li>
						</ul>
						<p>All files are deleted after 24 hours!</p>
					</div>
				</div>

				<img src={windGenerators} alt='windGenerator' />
			</div>

			{processStore._modalStore.modalActive ? <ResultDisplayModal /> : ''}
		</div>
	);
});
