import { injectable } from 'inversify';
import { makeAutoObservable } from 'mobx';
import { IValidationMessageDTO } from '../dto/IValidationMessageDTO';

@injectable()
export class ErrorStore {
	private _errors: IValidationMessageDTO[] | undefined;

	constructor() {
		makeAutoObservable(this);
	}

	public get errors(): IValidationMessageDTO[] | undefined {
		return this._errors;
	}

	public setErrors(value: IValidationMessageDTO[] | undefined): void {
		this._errors = value;
	}

	public getErrorListByName(fieldName: string | undefined): IValidationMessageDTO[] | undefined {
		const errorList = this._errors?.filter((error) => error.PropertyName === fieldName);
		return errorList;
	}

	public addError(error: IValidationMessageDTO): void {
		const newErrorList = this.errors ? [...this.errors, error] : [error];
		this.setErrors(newErrorList);
	}

	public addMultiplyError(errors: IValidationMessageDTO[]): void {
		errors.forEach((error) => this.addError(error));
	}

	public removeError(filedName: string | undefined): void {
		const newErrorList = this.errors?.filter((item) => item.PropertyName !== filedName);
		this.setErrors(newErrorList);
	}

	public resetErrors(): void {
		this.setErrors(undefined);
	}
}
