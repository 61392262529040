import { observer } from 'mobx-react';
import { API_URL } from '../../../constantsHub/constants';
import { ETaskState } from '../../../dto/ETaskState';
import { TaskResultState } from '../../../dto/TaskResultState';
import { useInject } from '../../../hooks/useInject';
import { ReactComponent as File } from '../../../img/general-icons/file.svg';
import { Types } from '../../../inversify/inversify.types';
import { ApiUrlFabric } from '../../../services/api/apiUrlFabric';
import { ProcessStore } from '../../../stores/processStore';
import { Button } from '../../UI/button/Button';
import { ProcessLine } from '../processLine/ProcessLine';
import './processBar.scss';

export const ProcessBar = observer((): JSX.Element => {
	const processStore = useInject<ProcessStore>(Types.ProcessStore);
	const apiUrlFabric = useInject<ApiUrlFabric>(Types.ApiUrlFabric);

	return (
		<div className='processing-bar__box'>
			<div className='processing-bar__box-left'>
				<span className='processing-bar__box-left-filename'>
					<File />
					{processStore.fileName}
				</span>
			</div>

			<ProcessLine />

			<div className='processing-bar__box-right'>
				{processStore.taskState === ETaskState.Completed &&
				(processStore.taskResultState === TaskResultState.Completed ||
					processStore.taskResultState === TaskResultState.CompletedWithWarning) ? (
					<>
						<a
							href={`${API_URL}${apiUrlFabric.downloadFile(processStore.taskId)}`}
							download={processStore.fileName}
						>
							<Button className='outline-variant'>Download</Button>
						</a>
						<Button
							className='active'
							onClick={(): void => {
								processStore.handleModalOpen();
							}}
						>
							View
						</Button>
					</>
				) : (
					''
				)}
			</div>
		</div>
	);
});
