import './progressBar.scss';

import { observer } from 'mobx-react-lite';
import { SizeTypeHelper } from '../../../helpers/sizeTypeHelper';

interface IProgressBarProps {
	completed: number;
	maxCompleted: number;
}

export const ProgressBar = observer(({ completed, maxCompleted, ...props }: IProgressBarProps): JSX.Element => {
	const fillerStyles = {
		width: `${(completed / maxCompleted) * 100}%`,
	};

	return (
		<div className='progress-bar__box'>
			<div style={fillerStyles} className='progress-bar__filler'></div>
			<span className='progress-bar__label'>
				{SizeTypeHelper.getRoundSize(completed)} / {SizeTypeHelper.getRoundSize(maxCompleted)}{' '}
				{SizeTypeHelper.getSizeType(maxCompleted)}
			</span>
		</div>
	);
});
