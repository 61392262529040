import { ReactComponent as InstagramLogo } from '../img/logo/instagram-logo.svg';
import { ReactComponent as LinkedinLogo } from '../img/logo/linkedin-logo.svg';
import { ReactComponent as YoutubeLogo } from '../img/logo/youtube-logo.svg';
import { INST_PATH, LINKEDIN_PATH, YOUTUBE_PATH } from './constants';

export interface ISocialLinks {
	path: string;
	icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

export class NavigateLinks {
	public static get socialLinks(): ISocialLinks[] {
		return [
			{
				path: INST_PATH,
				icon: InstagramLogo,
			},
			{
				path: LINKEDIN_PATH,
				icon: LinkedinLogo,
			},
			{
				path: YOUTUBE_PATH,
				icon: YoutubeLogo,
			},
		];
	}

	public static get mainPage(): string {
		return '/';
	}

	public static processPageWithId(id: string): string {
		return `/process/${id}`;
	}

	public static get errorPage(): string {
		return '/error';
	}
}
