import { inject, injectable } from 'inversify';
import { action, computed, makeObservable, observable } from 'mobx';
import { Types } from '../inversify/inversify.types';
import { history } from '../routing/history';
import { NavigateLinks } from '../routing/navigateLinks';
import { ProgressService } from '../services/api/progressService';
import { ISampleTableItemDTO } from '../dto/ISampleTableItemDTO';
import { ErrorStore } from './errorStore';

@injectable()
export class ProgressStore {
	@inject(Types.ProgressService) private _progressService!: ProgressService;
	@inject(Types.ErrorStore) private _errorStore!: ErrorStore;

	@observable private _taskId = '';
	@observable private _samples: ISampleTableItemDTO[] = [];

	constructor() {
		makeObservable(this);
	}

	@computed get taskId(): string {
		return this._taskId;
	}

	@action public setTaskId(value: string | undefined): void {
		if (value) {
			this._taskId = value;
		}
	}

	@computed get samples(): ISampleTableItemDTO[] {
		return this._samples;
	}

	@action public setSamples(value: ISampleTableItemDTO[]): void {
		this._samples = value;
	}

	public async getSamples(): Promise<void> {
		const res = await this._progressService.getSamples();
		if (res.isRight()) {
			this.setSamples(res.value.Items);
		}
	}

	public async createTask(FileId: string, Email: string): Promise<void> {
		const res = await this._progressService.createTask(FileId, Email);

		if (res.isRight()) {
			this.setTaskId(res.value);
			history.push(NavigateLinks.processPageWithId(res.value));
		} else if (res.isLeft()) {
			this._errorStore.setErrors(res.value.data.Errors);
		}
	}
}
