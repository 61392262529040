import './styles/index.scss';

import ReactDOM from 'react-dom/client';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import App from './App';
import { history } from './routing/history';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
	<HistoryRouter history={history}>
		<App />
	</HistoryRouter>,
);
