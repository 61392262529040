import { Container } from 'inversify';
import 'reflect-metadata';
import { ApiUrlFabric } from '../services/api/apiUrlFabric';
import { ProcessService } from '../services/api/processService';
import { ProgressService } from '../services/api/progressService';
import { UploadService } from '../services/api/uploadService';
import { SignalR } from '../signalR/signalR';
import { SignalRSubscribers } from '../signalR/signalRSubscribers';
import { ErrorStore } from '../stores/errorStore';
import { GeneralStore } from '../stores/generalStore';
import { ModalStore } from '../stores/modalStore';
import { ProcessStore } from '../stores/processStore';
import { ProgressStore } from '../stores/progressStore';
import UploadStore from '../stores/uploadStore';
import { Types } from './inversify.types';

const container = new Container();

container.bind<ApiUrlFabric>(Types.ApiUrlFabric).to(ApiUrlFabric);

// service
container.bind<UploadService>(Types.UploadService).to(UploadService);
container.bind<ProgressService>(Types.ProgressService).to(ProgressService);
container.bind<ProcessService>(Types.ProcessService).to(ProcessService);
// service end

// stores
container.bind<GeneralStore>(Types.GeneralStore).to(GeneralStore).inSingletonScope();
container.bind<UploadStore>(Types.UploadStore).to(UploadStore).inSingletonScope();
container.bind<ProgressStore>(Types.ProgressStore).to(ProgressStore).inSingletonScope();
container.bind<ProcessStore>(Types.ProcessStore).to(ProcessStore).inSingletonScope();
container.bind<ModalStore>(Types.ModalStore).to(ModalStore);
container.bind<ErrorStore>(Types.ErrorStore).to(ErrorStore).inSingletonScope();
// stores end

// signalR
container.bind<SignalR>(Types.SignalR).to(SignalR).inSingletonScope();
container.bind<SignalRSubscribers>(Types.SignalRSubscribers).to(SignalRSubscribers).inSingletonScope();
// signalR end

export default container;
