import { inject, injectable } from 'inversify';
import { action, computed, makeObservable, observable } from 'mobx';
import { HttpRequest, HttpResponse } from 'tus-js-client';
import { Types } from '../inversify/inversify.types';
import { UploadService } from '../services/api/uploadService';
import { ErrorStore } from './errorStore';
import { IValidationFailedDTO } from '../dto/IValidationFailedDTO';

@injectable()
export default class UploadStore {
	@inject(Types.UploadService) private _uploadService!: UploadService;
	@inject(Types.ErrorStore) private _errorStore!: ErrorStore;

	@observable private _email = '';
	@observable private _fileId: string | undefined;
	@observable private _fileName: string | undefined;
	@observable private _fileLoadTotal = 0;
	@observable private _fileLoadProgress = 0;

	constructor() {
		makeObservable(this);
	}

	@computed public get email(): string {
		return this._email;
	}

	@action public setEmail(value: string): void {
		this._email = value;
	}

	@computed public get fileId(): string | undefined {
		return this._fileId;
	}

	@action public setFileId(value: string | undefined): void {
		if (value === '') {
			value = undefined;
		}
		this._fileId = value;
	}

	@computed public get fileName(): string | undefined {
		return this._fileName;
	}

	@action public setFileName(value: string | undefined): void {
		if (value === '') {
			value = undefined;
		}
		this._fileName = value;
	}

	@computed get fileLoadTotal(): number {
		return this._fileLoadTotal;
	}

	@action private setFileLoadTotal(value: number): void {
		this._fileLoadTotal = value;
	}

	@computed get fileLoadProgress(): number {
		return this._fileLoadProgress;
	}

	@action private setFileLoadProgress(value: number): void {
		this._fileLoadProgress = value;
	}

	public removeFile(): void {
		this.setFileLoadTotal(0);
		this.setFileLoadProgress(0);
		this.setFileName(undefined);
		this.setFileId(undefined);
	}

	private onUploadProgress(bytesSent: number, bytesTotal: number): void {
		this.setFileLoadProgress(bytesSent);
		this.setFileLoadTotal(bytesTotal);
	}

	public async uploadFile(file: File): Promise<void> {
		this.setFileLoadProgress(0);
		this.setFileName(file.name);
		await this._uploadService.uploadFile(
			file,
			(bytesSent: number, bytesTotal: number) => this.onUploadProgress(bytesSent, bytesTotal),
			(id: string | undefined) => this.finishUploadFile(id),
			(req: HttpRequest, res: HttpResponse) => {
				if (res.getStatus() === 400) {
					const resBody = JSON.parse(res.getBody()) as IValidationFailedDTO;
					this.setFileName(undefined);
					this._errorStore.addMultiplyError(resBody.Errors);
				}
			},
		);
	}

	private finishUploadFile(id: string | undefined): void {
		this.setFileId(id);
	}

	public stopUploadFile(): void {
		this._uploadService.stopUploadFile();
	}
}
