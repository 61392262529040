import { inject, injectable } from 'inversify';
import { action, computed, makeObservable, observable } from 'mobx';
import { ErrorFieldNameConstants } from '../constantsHub/errorFiledNameConstants';
import { ETaskState } from '../dto/ETaskState';
import { ITaskViewDTO } from '../dto/ITaskViewDTO';

import { ErrorHelper } from '../helpers/errorHelper';
import { Types } from '../inversify/inversify.types';
import { history } from '../routing/history';
import { NavigateLinks } from '../routing/navigateLinks';
import { ProcessService } from '../services/api/processService';
import { SignalR } from '../signalR/signalR';
import { SignalRSubscribers } from '../signalR/signalRSubscribers';
import { IImageSizeDTO } from '../dto/IImageSizeDTO';
import { ErrorStore } from './errorStore';
import { ModalStore } from './modalStore';
import { ITaskQueueDTO } from '../dto/ITaskQueueDTO';
import { IDefectDTO } from '../dto/IDefectDTO';
import { TaskResultState } from '../dto/TaskResultState';

@injectable()
export class ProcessStore {
	@inject(Types.ProcessService) private _processService!: ProcessService;
	@inject(Types.SignalR) private _signalR!: SignalR;
	@inject(Types.SignalRSubscribers) private _signalRSubscribers!: SignalRSubscribers;
	@inject(Types.ErrorStore) private _errorStore!: ErrorStore;
	@inject(Types.ModalStore) public _modalStore!: ModalStore;

	@observable private _taskId = '';
	@observable private _queueNumber: number | undefined;
	@observable private _fileName: string | undefined;
	@observable private _taskState: ETaskState | undefined;
	@observable private _taskResultState: TaskResultState | undefined;
	@observable private _fileProperties: IImageSizeDTO | undefined;
	@observable private _defects: IDefectDTO[] = [];
	@observable private _threshold = 0;

	constructor() {
		makeObservable(this);
	}

	@computed get taskId(): string {
		return this._taskId;
	}

	@action public setTaskId(value: string | undefined): void {
		if (value) {
			this._taskId = value;
		}
	}

	@computed get queueNumber(): number | undefined {
		return this._queueNumber;
	}

	@action public setQueueNumber(value: number | undefined): void {
		this._queueNumber = value;
	}

	@computed get fileName(): string | undefined {
		return this._fileName;
	}

	@action public setFileName(value: string | undefined): void {
		this._fileName = value;
	}

	@computed get taskState(): ETaskState | undefined {
		return this._taskState;
	}

	@action public setTaskState(value: ETaskState | undefined): void {
		this._taskState = value;
	}

	@computed get taskResultState(): TaskResultState | undefined {
		return this._taskResultState;
	}

	@action public setTaskResultState(value: TaskResultState | undefined): void {
		this._taskResultState = value;
	}

	@computed get fileProperties(): IImageSizeDTO | undefined {
		return this._fileProperties;
	}

	@action public setFileProperties(value: IImageSizeDTO | undefined): void {
		this._fileProperties = value;
	}

	@computed get defects(): IDefectDTO[] {
		return this._defects;
	}

	@action public setDefects(value: IDefectDTO[]): void {
		this._defects = value;
	}

	@computed get threshold(): number {
		return this._threshold;
	}

	@action public setThreshold(value: number): void {
		this._threshold = value;
	}

	public async getQueueNumber(): Promise<void> {
		const res = await this._processService.getQueueNumber(this.taskId);

		if (res.isRight()) {
			this.setQueueNumber(res.value.QueueNumber);
		}
	}

	public async getTaskState(): Promise<void> {
		const res = await this._processService.getTaskState(this.taskId);

		if (res.isRight()) {
			this.taskStateReqIsRight(res.value);
		} else if (res.isLeft()) {
			history.push(NavigateLinks.errorPage);
		}
	}

	private taskStateReqIsRight(value: ITaskViewDTO): void {
		this.setFileName(value.Filename);
		this.setTaskState(value.State);
		this.setTaskResultState(value.Progress);
		if (value.State === ETaskState.Waiting) {
			this.getQueueNumber();
		}
		this._errorStore.setErrors([ErrorHelper.compileError(value.Message, ErrorFieldNameConstants.processingError)]);
		this.initSignalR(this.taskId);
	}

	private async initSignalR(id: string | undefined): Promise<void> {
		if (id) {
			this._signalR.initHub(id);
			this._signalRSubscribers.taskStateSubscriber.Add((data?: ITaskViewDTO) => {
				this.changeTaskState(data);
			});
			this._signalRSubscribers.taskQueueSubscriber.Add((data?: ITaskQueueDTO) => {
				this.setQueueNumber(data?.QueueNumber);
			});
		}
	}

	public stopSignalR(): void {
		this._signalR.stopHub();
	}

	public changeTaskState(data: ITaskViewDTO | undefined): void {
		if (data) {
			this.setTaskState(data.State);
			this.setTaskResultState(data.Progress);
			this._errorStore.setErrors([
				ErrorHelper.compileError(data.Message, ErrorFieldNameConstants.processingError),
			]);
			this.setFileProperties(data.ImageSize);
		}
	}

	public async getTaskDefects(): Promise<void> {
		const res = await this._processService.getTaskDefects(this.taskId);

		if (res.isRight()) {
			this.setDefects(res.value.Defects);
			this.setThreshold(res.value.Threshold);
		}
	}

	public handleModalOpen(): void {
		this._modalStore.setModalActive(true);
	}

	public handleModalClose(): void {
		this._modalStore.setModalActive(false);
	}
}
