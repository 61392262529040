import { action, computed, makeObservable, observable } from 'mobx';
import { inject, injectable } from 'inversify';
import * as tus from 'tus-js-client';
import { FileHelper } from '../../../helpers/fileHelper';
import { Types } from '../../../inversify/inversify.types';
import { ApiUrlFabric } from '../apiUrlFabric';

export interface RequestFileData<ConfigType> {
	file: File;
	options: ConfigType;
	onProgress?: (bytesUploaded: number, bytesTotal: number) => void;
	saveIdOnSuccess?: (id: string | undefined) => void;
}

@injectable()
export class TusApi {
	@inject(Types.ApiUrlFabric) protected _apiUrlFabric!: ApiUrlFabric;

	@observable protected _upload: tus.Upload | undefined;

	constructor() {
		makeObservable(this);
	}

	@computed get upload(): tus.Upload | undefined {
		return this._upload;
	}

	@action private setUpload(value: tus.Upload | undefined): void {
		this._upload = value;
	}

	protected _doApiRequest = async (upload: tus.Upload): Promise<void> => {
		this.setUpload(upload);

		if (this._upload) {
			const previousUploads: tus.PreviousUpload[] = await this._upload.findPreviousUploads();
			if (previousUploads.length) {
				this._upload.resumeFromPreviousUpload(previousUploads[0]);
			}

			this._upload.start();
		}
	};

	protected _post = (data: RequestFileData<tus.UploadOptions>): tus.Upload => {
		data.options.onError = data.options.onError || this._interceptOnReject;
		data.options.onSuccess = (): void => {
			if (data.saveIdOnSuccess) {
				const id = FileHelper.getIdFileFromUrl(upload.url);
				data.saveIdOnSuccess(id);
			}
		};
		const upload = new tus.Upload(data.file, data.options);
		return upload;
	};

	private _interceptOnReject(error: unknown): void {
		console.error(error);
	}
}
