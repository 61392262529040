import { Either } from '@sweet-monads/either';
import { AxiosResponse } from 'axios';
import { injectable } from 'inversify';
import { IValidationFailedDTO } from '../../dto/IValidationFailedDTO';
import { ITableItemDTO } from '../../dto/ITableItemDTO';
import { ISampleTableItemDTO } from '../../dto/ISampleTableItemDTO';
import { AxiosApi } from './base/axiosApi';

@injectable()
export class ProgressService extends AxiosApi {
	public async getSamples(): Promise<Either<unknown, ITableItemDTO<ISampleTableItemDTO>>> {
		const req = this._get<ITableItemDTO<ISampleTableItemDTO>>({ url: this._apiUrlFabric.samples });
		return this._doApiRequest(req);
	}

	public async createTask(
		FileId: string,
		Email: string,
	): Promise<Either<AxiosResponse<IValidationFailedDTO>, string>> {
		const req = this._post<string>({ url: this._apiUrlFabric.task, payload: { FileId, Email } });
		return this._doApiRequest(req);
	}
}
