import './input.scss';

import classNames from 'classnames';
import { observer } from 'mobx-react';
import { InputHTMLAttributes } from 'react';
import { ErrorStore } from '../../../stores/errorStore';

import { ReactComponent as ErrorIcon } from '../../../img/general-icons/error.svg';
interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
	inputName?: string;
	errorStore?: ErrorStore;
}

export const Input = observer(({ inputName, errorStore, ...props }: IInputProps): JSX.Element => {
	const errorList = inputName ? errorStore?.getErrorListByName(inputName) : undefined;

	return (
		<div className='input__box'>
			<input className={classNames('input', { error: errorList?.length })} {...props} />
			{errorList?.map((item, indx) => {
				return (
					<p
						className={classNames('input__error', { error: errorList?.length })}
						key={`${item.PropertyName}-error-${indx}`}
					>
						<ErrorIcon />
						{item.Message}
					</p>
				);
			})}
		</div>
	);
});
