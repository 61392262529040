import { injectable } from 'inversify';

@injectable()
export class ApiUrlFabric {
	public get files(): string {
		return `/files/`;
	}

	public get samples(): string {
		return `/samples`;
	}

	public get task(): string {
		return '/task/';
	}

	public taskInQueue(id: string): string {
		return `${this.task}${id}/queue`;
	}

	public taskWithId(id: string): string {
		return `${this.task}${id}`;
	}

	public downloadFile(id: string): string {
		return `${this.task}${id}/download`;
	}

	public taskDefects(id: string): string {
		return `${this.taskWithId(id)}/defects`;
	}

	public downloadImage(id: string, threshold: number): string {
		return `${this.taskWithId(id)}/image?threshold=${threshold}`;
	}
}
